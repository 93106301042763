import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import "../InseedProgram.scss";
import {
  Strings,
  requiredFeild,
  moduleLabels,
  addModuleErrorsValidations,
  inseedMastertechnologyOptions,
} from "../../../constant/Strings";
import {
  moduleMasterTrainerNameValidation,
  moduleMasterInstituteNameValidation,
  employeeMobileNumberValidtion,
} from "../../../constant/Validations";
import { Dropdown, Form } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";

const EditModule = (props: any) => {
  const { hideEditModule, moduleDetails, apiCallCompleted } = props;
  const [techOtherField, setTechOtherField] = useState("");
  const [technologySteamOption,setTechnologyStreamOption]= useState([]);
  const [technologySteam,setTechnologyStream]= useState("");
  const {
    submit,
    cancel,
    imageSize2mb,
    uploadFile,
    uploadSyllabus,
    editSuccess,
    addSuccess
  } = Strings;
  const {
    moduleNameRequired,
    technologyStreamRequired,
    durationRequired,
    trainerNameRequired,
    trainerName,
    instituteName,
    trainerContactNumber,
  } = addModuleErrorsValidations;
  const [btnDisable, setBtnDisable] = useState(false);
  const [moduleData, setModuleData] = useState<any>({
    moduleId: moduleDetails.moduleId,
    moduleName: moduleDetails.moduleName,
    content: moduleDetails.content,
    duration: moduleDetails.duration,
    successCriteria: moduleDetails.successCriteria,
    description: moduleDetails.description,
    trainerName: moduleDetails.trainerName,
    trainerContactNumber: moduleDetails.trainerContactNumber,
    technologyStream: moduleDetails.technologyStream,
    instituteName: moduleDetails.instituteName,
    syllabus: moduleDetails.syllabus,
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    moduleName: "",
    syllabus: "",
    duration: "",
    successCriteria: "",
    technologyStream: "",
    trainerName: "",
    trainerContactNumber: "",
    instituteName: "",
  });

  useEffect(() => {
    if (
      moduleData.moduleName &&
      moduleData.duration &&
      moduleData.technologyStream &&
      moduleData.trainerName &&
      moduleData.trainerContactNumber
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  let valid: boolean = true;
  const validate = () => {
    if (moduleData.moduleName.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        moduleName: moduleNameRequired,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, moduleName: "" }));
    }
    if (moduleData.technologyStream.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        technologyStream: technologyStreamRequired,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        technologyStream: "",
      }));
    }
    if (moduleData.duration.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        duration: durationRequired,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        duration: "",
      }));
    }
    if (moduleData.trainerName.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        trainerName: trainerNameRequired,
      }));
      setBtnDisable(false);
    }
    if (moduleMasterTrainerNameValidation(moduleData.trainerName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        trainerName: trainerName,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        trainerName: "",
      }));
    }
    if (moduleData.instituteName) {
      if (
        moduleMasterInstituteNameValidation(moduleData.instituteName) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          instituteName: instituteName,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          instituteName: "",
        }));
      }
    }

    if (moduleData.trainerContactNumber.length < 10) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        trainerContactNumber: trainerContactNumber,
      }));
      setBtnDisable(false);
    }
    if (
      employeeMobileNumberValidtion(
        moduleData.trainerContactNumber.toString()
      ) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        trainerContactNumber: trainerContactNumber,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        trainerContactNumber: "",
      }));
    }
  };
  const handleModuleSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideEditModule();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editInseedModuleAction(
        moduleData.moduleId,
        moduleData,
        (res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else if (res.code === 200) {
                showSuccessToastMessage(editSuccess);
                apiCallCompleted(true);
              }
        }
      )
    );
  };

  const [docFile, setDocFile] = useState<any>({
    content: null,
  });
  const handleModuleSyllabusSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      e.target.value = "";
      return;
    } else if (
      ![
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG, DOC, XLSX, PPT and PDF."
      );
      e.target.value = "";
      return;
    } else {
      setDocFile({ ...docFile, content: e.target.files[0] });
      dispatch(
        actions.uploadModuleSyllabusAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setModuleData({
                ...moduleData,
                content: res.data,
              });
            }
          }
        )
      );
    }
  };
  const getTechnologyStreamApi =() => {
      dispatch(
        actions.GetTechnologySteamAction((res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if(res?.code === 200) {
                setTechnologyStreamOption(res.data);
          }
        })
      );
    }
    useEffect(() => {
      getTechnologyStreamApi()
    }, []);
      const handleTechnologyChange = (e: any) => {
        e.preventDefault();
        const payload = {
          status: 1,
          inseedMasterTechnologyName: technologySteam
        }
        dispatch(
          actions.addTechnologySteamAction(payload, (res: any) => {
             if (res?.error?.error_description) {
                  showErrorToastMessage(res.error.error_description);
                  setTechnologyStream("");
                } else if(res?.code === 200) {
                  showSuccessToastMessage(addSuccess);
                  setTechnologyStream("");
                  getTechnologyStreamApi()
            }
          })
        );
        const inputField: any = document.getElementById("technolgyAdd");
        if (inputField) {
          inputField.value = "";
        }
      };
  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.moduleName}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={moduleData.moduleName}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                moduleName: e.target.value,
              })
            }
          />
          <p className="error">{errors.moduleName}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.technologyStream}
          </label>
          <span className="required">*</span>

          <Dropdown
            onSelect={(eventKey: any) => {
              setModuleData({
                ...moduleData,
                technologyStream: eventKey,
              });
            }}
          >
            <Dropdown.Toggle
              variant="light"
              className="form-control training-location-dropdown"
            >
              {moduleData.technologyStream ? moduleData.technologyStream : "Select technology"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="technolgyAdd">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setTechnologyStream(e.target.value)
                        }
                        id="technolgyAdd"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          technologySteam.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleTechnologyChange}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.technologyStream}</p>
                  </div>
                </Form.Group>
              </Form>
              {technologySteamOption?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.inseedMasterTechnologyName}>
                  {ele.inseedMasterTechnologyName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {(techOtherField == "Other" ||
          !inseedMastertechnologyOptions.find(
            (data: any) => data.value == moduleData.technologyStream
          )) && (
          <>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {moduleLabels.technologyStream}
              </label>
              <input
                type="text"
                className="form-control"
                value={
                  moduleData.technologyStream == "Other"
                    ? ""
                    : moduleData.technologyStream
                }
                onChange={(e) =>
                  setModuleData({
                    ...moduleData,
                    technologyStream: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.duration}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            maxLength={2}
            type="text"
            className="form-control"
            value={moduleData.duration}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                duration: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.duration}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.trainerName}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={moduleData.trainerName}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                trainerName: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.trainerName}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.trainerContactNumber}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            maxLength={10}
            className="form-control"
            value={moduleData.trainerContactNumber}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                trainerContactNumber: e.target.value,
              })
            }
          />
          <p className="error">{errors.trainerContactNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.trainerInstitute}
          </label>
          <input
            type="text"
            className="form-control"
            id="instituteName"
            value={moduleData.instituteName}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                instituteName: e.target.value,
              })
            }
          />
          <p className="error">{errors.instituteName}</p>
        </div>
        <div className="col-md-6">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.syllabus}
          </label>
          <input
            type="text"
            className="form-control"
            id="syllabus"
            value={moduleData.syllabus}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                syllabus: e.target.value,
              })
            }
          />
          <p className="error">{errors.syllabus}</p>
        </div>
        <div className="col-md-6">
          <label htmlFor="inputName4" className="form-label">
            {moduleLabels.successCriteria}
          </label>
          <input
            type="text"
            className="form-control"
            value={moduleData.successCriteria}
            onChange={(e) =>
              setModuleData({
                ...moduleData,
                successCriteria: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">{uploadSyllabus}</label>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleModuleSyllabusSubmit}
              hidden
              id="content-id"
            />
            <label
              htmlFor="content-id"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="form-label">
            {imageSize2mb}
            <span className="required">{requiredFeild.required}</span>
          </p>
          <a
            href={moduleData.content?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {moduleData.content?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile.content?.name}</p>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditModule}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleModuleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModule;
