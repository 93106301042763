import  { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import Select from "react-select";
import "../InseedProgram.scss";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import ModalWarning from "../../../component/MakerChecker/ModalWarning";
import ReasonForRejection from "../../../component/MakerChecker/ReasonForRejection";
import {
  Strings,
  inseedMasterValidationStrings,
  inseedRecordStatusDropdown,
  requiredFeild,
  inseedMastertechnologyOptions,
} from "../../../constant/Strings";
import {
  inseedLocationValidation,
  isCharactersValid,
  whiteSpaceValid,
} from "../../../constant/Validations";
import { AiOutlineCheck } from "react-icons/ai";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const EditInseedProgram = (props: any) => {
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const { inseedDetails, hideEditInseedProgram, apiCallCompleted } = props;
  const [techOtherField, setTechOtherField] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const {
    client,
    training_location,
    technology_Stream,
    technology_sub_stream,
    work_location,
    batch_size,
    required_hc,
    stipend_per_month,
    proposed_ctc,
    training_cost,
    tco_sub,
    date_of_onBoarding,
    inseed_program_SPOC,
    inseed_program_owner,
    remarks,
    start_date,
    end_date,
    cancel,
    submit,
    editSuccess,
    inseed_batch,
    batch_status,
    approve,
    reject,
    addSuccess,
    LocationLabel
  } = Strings;
  const {
    inseedProgramOwner_error,
    spoc_error,
    intakeHC_error,
    ctc_error,
    stipend_error,
    location_error,
  } = inseedMasterValidationStrings;
  const [inseedData, setinseedData] = useState<any>({
    client: inseedDetails.client,
    technology: inseedDetails.technology,
    technologySubStream: inseedDetails.technologySubStream,
    intakeHC: inseedDetails.intakeHC,
    productionHC: inseedDetails.productionHC,
    stipend: inseedDetails.stipend,
    ctc: inseedDetails.ctc,
    trainingCost: inseedDetails.trainingCost,
    trainingLocation: inseedDetails.trainingLocation,
    location: inseedDetails.location,
    startDate: inseedDetails.startDate,
    endDate: inseedDetails.endDate,
    status: "Updated",
    recordStatus: inseedDetails.recordStatus,
    remarks2: inseedDetails.remarks2,
    dateOfOnBoarding: inseedDetails?.dateOfOnBoarding,
    inseedProgramOwner: inseedDetails?.inseedProgramOwner,
    spoc: inseedDetails?.spoc,
  });
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    location: "",
    intakeHC: "",
    stipend: "",
    ctc: "",
    inseedProgramOwner: "",
    spoc: "",
  });
  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const [technologySteamOption,setTechnologyStreamOption]= useState([]);
  const [technologySteam,setTechnologyStream]= useState("");
  const [workLocationOption,setworkLocationOption]= useState<any>([]);
  const [WorkLocationValue,setWorkLocationValue]= useState("");
  const handleRejectModal = () => {
    setShowReasonForRejection(true);
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const [modalWarning, setModalWarning] = useState("");
  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  useEffect(() => {
    const isOtherSelected = inseedData?.trainingLocation?.includes("Other");
    const isFormComplete =
      inseedData?.client &&
      inseedData?.technology &&
      inseedData?.intakeHC &&
      inseedData?.stipend &&
      inseedData?.ctc &&
      inseedData?.location &&
      inseedData?.startDate &&
      inseedData?.endDate &&
      inseedData?.inseedProgramOwner &&
      inseedData?.spoc;

    const isTrainingLocationValid = isOtherSelected
      ? WorkLocationValue && inseedData?.trainingLocation?.length > 0
      : inseedData?.trainingLocation?.length > 0;

    if (isFormComplete && isTrainingLocationValid) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [
    inseedData,
    WorkLocationValue,
    inseedData?.trainingLocation,
    setBtnDisable,
  ]);

  useEffect(() => {
    dispatch(actions.getClientNameAction());
  }, []);
  const [clientName, setClientName] = useState([]);
  const getClientName = useSelector(
    (state: any) => state.employeeReducer.getClientNameDetails
  );
  useEffect(() => {
    if (getClientName) {
      if (getClientName.data) {
        setClientName(getClientName.data);
      }
    }
  }, [getClientName, clientName]);
  const clientOptions: any = clientName.map((ele: any) => ({
    value: ele.clientName,
    label: ele.clientName,
  }));
  const handleClientName = (selected: any) => {
    setinseedData({
      ...inseedData,
      client: selected.value,
    });
  };

  let valid: boolean = true;
  const validate = () => {
    if (inseedData?.location) {
      if (inseedLocationValidation(inseedData?.location) == false) {
        valid = false;
        setErrors((error) => ({
          ...error,
          location: location_error,
        }));
      } else {
        setErrors((error) => ({
          ...error,
          location: "",
        }));
      }
    }

    if (inseedData.ctc) {
      if (
        parseInt(inseedData.ctc) < 250000 ||
        parseInt(inseedData.ctc) > 600000
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          ctc: ctc_error,
        }));
      } else {
        setErrors((error) => ({
          ...error,
          ctc: "",
        }));
      }
    }

    if (inseedData.inseedProgramOwner) {
      if (
        whiteSpaceValid(inseedData.inseedProgramOwner) == false ||
        isCharactersValid(inseedData.inseedProgramOwner) == false
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          inseedProgramOwner: inseedProgramOwner_error,
        }));
      } else {
        setErrors((error) => ({
          ...error,
          inseedProgramOwner: "",
        }));
      }
    }
    if (inseedData.intakeHC) {
      if (inseedData.intakeHC > 50) {
        valid = false;
        setErrors((error) => ({
          ...error,
          intakeHC: intakeHC_error,
        }));
      } else {
        setErrors((error) => ({
          ...error,
          intakeHC: "",
        }));
      }
    }
    if (inseedData.spoc) {
      if (
        whiteSpaceValid(inseedData.spoc) == false ||
        isCharactersValid(inseedData.spoc) == false
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          spoc: spoc_error,
        }));
      } else {
        setErrors((error) => ({
          ...error,
          spoc: "",
        }));
      }
    }
  };
  const getTechnologyStreamApi =() => {
    dispatch(
      actions.GetTechnologySteamAction((res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res?.code === 200) {
              setTechnologyStreamOption(res.data);
        }
      })
    );
  }

  const getWorkLocationApi =() => {
    dispatch(
      actions.GetWorkLocationAction((res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res?.code === 200) {
              const MappedData = res?.data?.map((ele: any) => {
                return { value: ele.workLocationName, label: ele.workLocationName };
              });
              if(MappedData?.length){
              setworkLocationOption([...MappedData, { value: "Other", label: "Other" }]);
              }
              
        }
      })
    );
  }
  useEffect(() => {
    getTechnologyStreamApi()
    getWorkLocationApi()
  }, []);

  const handleEditInseedProgramSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideEditInseedProgram();
    }
  };
  const handleDispatch = () => {
    const callOtherAddApi = inseedData && inseedData?.trainingLocation?.includes("Other");
    apiCallCompleted(false);
    dispatch(
      actions.editInseedDataAction(
        inseedDetails._id,
        inseedData,
        (res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else  if (res.code === 200) {
                  if(callOtherAddApi === true){
                                const payload = {
                                  status: 1,
                                  workLocationName: WorkLocationValue
                                }
                                dispatch(
                                  actions.addWorkLocationAction(payload, (res: any) => {
                                     if (res?.error?.error_description) {
                                      setWorkLocationValue("");
                                          showErrorToastMessage(res.error.error_description);
                                        } else if(res?.code === 200) {
                                          setWorkLocationValue("");
                                          getWorkLocationApi()
                                    }
                                  })
                                );
                              }
                showSuccessToastMessage(editSuccess);
                apiCallCompleted(true);
          }
        }
      )
    );
  };
  const handledDateChange = (e: any) => {
    let newStartDate = e.target.value;
    let newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + 90);
    let formattedEndDate = newEndDate.toISOString().split("T")[0];
    setinseedData({
      ...inseedData,
      startDate: newStartDate,
      endDate: formattedEndDate,
    });
  };

  

   const handleTechnologyChange = (e: any) => {
      e.preventDefault();
      const payload = {
        status: 1,
        inseedMasterTechnologyName: technologySteam
      }
      dispatch(
        actions.addTechnologySteamAction(payload, (res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                setTechnologyStream("");
              } else if(res?.code === 200) {
                showSuccessToastMessage(addSuccess);
                setTechnologyStream("");
                getTechnologyStreamApi()
          }
        })
      );
      const inputField: any = document.getElementById("technolgyAdd");
      if (inputField) {
        inputField.value = "";
      }
    };

  const [showRequirements, setShowRequirements] = useState(false);
  const handleFocus = () => {
    setShowRequirements(true);
  };
  const handleBlur = () => {
    setShowRequirements(false);
  };
  const [selectedOptions, setSelectedOptions] = useState<any>(
    inseedData.trainingLocation.map((workLocation: any) => ({
      value: workLocation,
      label: workLocation,
    }))
  );

  const getLocationName = useSelector(
    (state: any) => state.employeeReducer.getInseedLocationData
  );
  const addLocationName = useSelector(
    (state: any) => state.employeeReducer.addInseedLocationData
  );
  useEffect(() => {
    dispatch(actions.getInseedLocationAction());
  }, [addLocationName]);

  const [location, setLocation] = useState({
    trainingLocationName: "",
  });
  const handleDropDownLocationSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.addInseedLocationAction(location, (res: any) => {
        if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            }else if (res.code === 200) {
              showSuccessToastMessage(addSuccess);
              setLocation({ ...location, trainingLocationName: "" });
        }
      })
    );
    const inputField: any = document.getElementById("formAddOption");
    if (inputField) {
      inputField.value = "";
    }
  };

  const [locationName, setLocationName] = useState([]);
  useEffect(() => {
    if (getLocationName) {
      if (getLocationName.data) {
        setLocationName(getLocationName.data);
      }
    }
  }, [getLocationName, locationName]);
  const handleOptionChange = (option: any, isChecked: boolean) => {
    let updatedOptions;

    if (isChecked) {
      updatedOptions = [...selectedOptions, option];
    } else {
      updatedOptions = selectedOptions.filter(
        (o: any) => o.value !== option.value
      );
    }

    setSelectedOptions(updatedOptions);
    setinseedData({
      ...inseedData,
      trainingLocation: updatedOptions.map((o: any) => o.value),
    });
  };

  const showOtherLocation = selectedOptions?.length && selectedOptions.some((ele:any) => ele.value === "Other")
  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {inseed_batch}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={inseedDetails.inseedBatch}
            disabled
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {client}
          </label>
          <span className="required">*</span>
          <div style={{ height: "1rem" }}>
            <Select
              className="dropdown-select"
              placeholder={inseedData.client}
              options={clientOptions}
              isSearchable={true}
              onChange={handleClientName}
            />
          </div>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {training_location}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) => {
              setinseedData({
                ...inseedData,
                location: eventKey,
              });
            }}
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {inseedData.location}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="formAddOption">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setLocation({
                            ...location,
                            trainingLocationName: e.target.value,
                          })
                        }
                        id="formAddOption"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDropDownLocationSubmit}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.location}</p>
                  </div>
                </Form.Group>
              </Form>
              {locationName?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.trainingLocationName}>
                  {ele.trainingLocationName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {start_date}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            className="form-control"
            value={timeDateFormatYMD(inseedData.startDate)}
            onChange={(e) => {
              handledDateChange(e);
            }}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {end_date}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            className="form-control"
            value={timeDateFormatYMD(inseedData.endDate)}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                endDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {technology_Stream}
          </label>
          <span className="required">*</span>

         
           <Dropdown
            onSelect={(eventKey: any) => {
              setinseedData({
                ...inseedData,
                technology: eventKey,
              });
            }}
          >
            <Dropdown.Toggle
              variant="light"
              className="form-control training-location-dropdown"
            >
              {inseedData.technology ? inseedData.technology : "Select technology"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="technolgyAdd">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setTechnologyStream(e.target.value)
                        }
                        id="technolgyAdd"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          technologySteam.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleTechnologyChange}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.location}</p>
                  </div>
                </Form.Group>
              </Form>
              {technologySteamOption?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.inseedMasterTechnologyName}>
                  {ele.inseedMasterTechnologyName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {technology_sub_stream}
          </label>
          <input
            type="text"
            className="form-control"
            value={inseedData.technologySubStream}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                technologySubStream: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-8 buttonsize">
          <label htmlFor="inputName4" className="form-label">
            {work_location}
          </label>
          <span className="required">*</span>
          <Select
            options={workLocationOption}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => {
              setSelectedOptions(selected);
              setinseedData({
                ...inseedData,
                trainingLocation: selected.map((o: any) => o.value),
              });
            }}
            closeMenuOnSelect={false}
            placeholder="Select location..."
            className="dropdown-select"
            components={{
              Option: (props: any) => {
                const isChecked = selectedOptions.some(
                  (selectedOption: any) => selectedOption.value === props.data.value
                );

                return (
                  <div className="custom-option buttonsize">
                    <div className="row">
                      <div className="col-8 mx-4 mt-2">{props.label}</div>
                      <div className="col-2 mt-2">
                        <input
                          type="checkbox"
                          onChange={() => handleOptionChange(props.data, !isChecked)}
                          checked={isChecked}
                        />
                      </div>
                    </div>
                  </div>
                );
              },
            }}
          />
        </div>
        {showOtherLocation === true && (
          <>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {LocationLabel}
              </label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setWorkLocationValue(e.target.value)
                }
              />
            </div>
          </>
        )}
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {batch_size}
          </label>
          <span className="required">*</span>
          <input
            maxLength={2}
            type="text"
            className="form-control"
            value={inseedData?.intakeHC}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                intakeHC: e.target.value.replace(/\D/g, ""),
              })
            }
          />

          <p className="error">{errors.intakeHC}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {required_hc}
          </label>

          <input
            maxLength={2}
            type="text"
            className="form-control"
            value={inseedData?.productionHC}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                productionHC: e.target.value.replace(/\D/g, ""),
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {stipend_per_month}
          </label>
          <span className="required">*</span>
          <input
            // minLength={4}
            // maxLength={5}
            type="text"
            className="form-control"
            value={inseedData.stipend}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                stipend: e.target.value.replace(/\D/g, ""),
              })
            }
          />

          <p className="error">{errors.stipend}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {proposed_ctc}
          </label>
          <span className="required">*</span>
          <input
            minLength={6}
            maxLength={6}
            type="text"
            className="form-control"
            value={inseedData.ctc}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                ctc: e.target.value.replace(/\D/g, ""),
              })
            }
          />

          <p className="error">{errors.ctc}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {training_cost}
          </label>
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={9}
            type="text"
            className="form-control"
            value={inseedData.trainingCost}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                trainingCost: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          {showRequirements && (
            <div className="tco-popup tco-popup-edit">
              <div className="d-flex">
                <span className="required">*</span>
                <span className="buttonsize">{tco_sub}</span>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {date_of_onBoarding}
          </label>
          <input
            min={new Date().toISOString().split("T")[0]}
            value={timeDateFormatYMD(inseedData.dateOfOnBoarding)}
            type="date"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                dateOfOnBoarding: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {inseed_program_owner}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            value={inseedData.inseedProgramOwner}
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                inseedProgramOwner: e.target.value,
              })
            }
          />
          <p className="error">{errors.inseedProgramOwner}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {inseed_program_SPOC}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            value={inseedData.spoc}
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                spoc: e.target.value,
              })
            }
          />
          <p className="error">{errors.spoc}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {remarks}
          </label>
          <input
            type="text"
            className="form-control"
            value={inseedData.remarks2}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                remarks2: e.target.value,
              })
            }
          />
        </div>
        {(inseedDetails?.status == "Approved" ||
          inseedDetails?.status == "Rejected" ||
          inseedDetails?.status == "Updated") && (
          <div className="col-md-4">
            <label className="form-label">
              {batch_status}
              <span className="required">*</span>
            </label>
            <Dropdown
              onSelect={(eventKey: any) =>
                setinseedData({
                  ...inseedData,
                  recordStatus: eventKey,
                })
              }
            >
              <Dropdown.Toggle variant="light" className="form-control">
                {inseedData.recordStatus}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {inseedRecordStatusDropdown.map((value: any) => (
                  <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <div className="row mt-5">
          {inseedDetails?.status == "Approved" ||
          inseedDetails?.status == "Rejected" ? (
            <></>
          ) : (
            <>
              {isInseedChecker && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-success buttonsize mx-3"
                    onClick={() => {
                      handleApproval("Approve");
                    }}
                  >
                    {approve}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger buttonsize mx-3"
                    onClick={() => {
                      handleRejectModal();
                    }}
                  >
                    {reject}
                  </button>
                </div>
              )}
            </>
          )}
          <div className="modal-btn-wrapper mt-5 mx-auto">
            <div>
              <button
                className="btn cancel-btn buttonsize"
                onClick={hideEditInseedProgram}
              >
                {cancel}
              </button>
            </div>
            <div>
              <button
                className={
                  btnDisable
                    ? "btn add-submit-button buttonsize"
                    : "btn add-submit-button btn-disabled buttonsize"
                }
                onClick={handleEditInseedProgramSubmit}
              >
                {submit}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
          apiCallCompleted = {apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={inseedDetails._id}
            editApi={actions.editInseedDataAction}
            data={{ status: modalWarning + "d" }}
            hideEditModal={hideEditInseedProgram}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
          apiCallCompleted = {apiCallCompleted}
            hideModal={hideRejectModal}
            id={inseedDetails._id}
            editApi={actions.editInseedDataAction}
            statusKey="status"
            hideEditModal={hideEditInseedProgram}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default EditInseedProgram;
