import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Button from "../../../component/Buttons/Button";
import { FaSort } from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import AddInseedResource from "./AddInseedResource";
import EditInseedResource from "./EditInseedResource";
import DeleteInseedResource from "./DeleteInseedResource";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import * as actions from "../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as XLSX from "xlsx";
import "./ResourceMaster.scss";
import { trainingMasterFilters, Strings } from "../../../constant/Strings";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../../component/FormatDate";

const ResourceMaster = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const [resourceDetails, setResourceDetails] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [inseedBatchName, setInseedBatchName] = useState("");
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [showExportReport, setShowExportReport] = useState(false);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [inseedBatches, setinseedBatches] = useState<any>([]);
  const [inseedBatchId, setinseedBatchId] = useState<any>("");
  const selectedInseedBatchId = inseedBatchId.split("&")[0];
  const [inseedBatchStartDate, setInseedBatchStartDate] = useState("");
  const selectedInseedRecordStatus = inseedBatchId.split("&")[1];
  const {
    hamburger_icon,
    export_report,
    template_icon,
    bulk_upload_icon,
    arrow_drop_down,
    description,
  } = icons;
  const {
    exportReport,
    template,
    bulk_upload,
    trainee_master,
    select_by_batch,
    filter_by_record_status,
    no_data_found,
    agreement_letter,
    offer_letter,
    certificate,
    on_boarding_date,
    emergency_contact_no,
  } = Strings;

  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilter] = useState("");
  const pageVisited = 0 * 10;

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "candidateName") {
          if (a?.Employee[0].candidateName < b?.Employee[0].candidateName) {
            return 1;
          }
          if (a?.Employee[0].candidateName > b?.Employee[0].candidateName) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const [showAddInseedResource, setshowAddInseedResource] = useState(false);
  const handleAddInseedResource = () => {
    setshowAddInseedResource(true);
  };
  const hideAddModal = () => {
    setshowAddInseedResource(false);
  };

  const [showEditInseedResource, setshowEditInseedResource] = useState(false);
  const handleEdit = (row: any) => {
    setResourceDetails(row);
    setshowEditInseedResource(true);
  };

  const hideEditModal = () => {
    setshowEditInseedResource(false);
  };

  const [showDeleteInseedResource, setshowDeleteInseedResource] =
    useState(false);
  const handleDelete = (id: any) => {
    setDeleteId(id);
    setshowDeleteInseedResource(true);
  };

  const hideDeleteModal = () => {
    setshowDeleteInseedResource(false);
  };

  const dispatch = useDispatch();

  const addInseedResource = useSelector(
    (state: any) => state.employeeReducer.addInseedResource
  );
  const editInseedResource = useSelector(
    (state: any) => state.employeeReducer.editInseedResourceData
  );
  const deleteInseedResource = useSelector(
    (state: any) => state.employeeReducer.deleteInseedResource
  );

  const options: any = [
    { value: "All Records", label: "All Records" },
    { value: "In Training", label: "In Training" },
    { value: "Mapped to Client Project", label: "Mapped to Client Project" },
    {
      value: "Mapped to Internal Project",
      label: "Mapped to Internal Project",
    },
    { value: "Mapped to RMG", label: "Mapped to RMG" },
    { value: "New", label: "New" },
    { value: "Terminated", label: "Terminated" },
    { value: "Released", label: "Released" },
    { value: "Resigned", label: "Resigned" },
  ];

  const getModuleBulkUpload = useSelector(
    (state: any) => state.employeeReducer.moduleBulkUploadDetails
  );

  useEffect(() => {
    unAuthorizeUser();
    dispatch(
      actions.getApprovedInseedDataAction("", "", "", "", "", (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        } else if (res.code === 200) {
          setinseedBatches(res.data);
          setinseedBatchId(res.data[0]._id + "&" + res.data[0]?.recordStatus);
          setInseedBatchName(res.data[0].inseedBatch);
          setInseedBatchStartDate(res.data[0]?.startDate);
        }
      })
    );
  }, []);

  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const filterKey = filterValue.map((ele: any) => ele.value);
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  useEffect(() => {
    setExpansion(false);
    setRowid("");
    if (condition) {
      dispatch(
        actions.getInseedDataPagAction(
          selectedInseedBatchId,
          "",
          page,
          pagePerSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              setdata(res.data);
              setTotalPages(res.meta_data.totalPageCount);
              setTotalEntries(res.meta_data.totalEntries);
              setTotalRecords(res.data.length);
              setOnclickSearch(false);
            }
          }
        )
      );
    }
  }, [
    page,
    searchValue,
    "",
    filterValue,
    pagePerSize,
    selectedInseedBatchId,
    "",
    editInseedResource,
    deleteInseedResource,
    getModuleBulkUpload,
    addInseedResource,
    condition,
  ]);

  const COLUMNS: any = [
    {
      Header: "Trainee Name",
      accessor: "candidateName",
    },
    {
      Header: "Contact Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Email",
      accessor: "emailId",
    },

    {
      Header: "DOJ-INSEED",
      accessor: "startDate",
    },
    {
      Header: "LWD-INSEED",
      accessor: "endDate",
    },

    {
      Header: "Status",
      accessor: "recordStatus",
    },
    {
      Header: "Record Status",
      accessor: "status",
    },
    {
      Header: "Action",
    },
  ];
  const MakerCheckerCOLUMNS: any = [
    {
      Header: "Trainee Name",
      accessor: "candidateName",
    },
    {
      Header: "Contact Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Email",
      accessor: "emailId",
    },

    {
      Header: "DOJ-INSEED",
      accessor: "startDate",
    },
    {
      Header: "LWD-INSEED",
      accessor: "endDate",
    },

    {
      Header: "Status",
      accessor: "recordStatus",
    },
    {
      Header: "Record Status",
      accessor: "status",
    },
  ];
  const columns = useMemo(
    () => (isInseedChecker || isInseedMaker ? COLUMNS : MakerCheckerCOLUMNS),
    [isInseedChecker, isInseedMaker]
  );

  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getInseedDataPagAction(
        selectedInseedBatchId,
        "",
        1,
        totalEntries,
        filterKey,
        searchValue,
        (res: any) => {
          if (res.code === 200) {
            if (res?.data.length > 0) {
              var traineeMasterData = [
                [
                  "Trainee Name",
                  "Contact Number",
                  "Email",
                  "DOJ-INSEED",
                  "LWD-INSEED",
                  "Status",
                  "Record Status",
                  "Emergency Contact Number",
                  "On Boarding Date",
                ],
              ];
              res?.data?.forEach((ele: any) => {
                let emptyArray: any = [];
                emptyArray.push(
                  ele?.resourceName,
                  ele?.contactNumber,
                  ele?.personalEmail,
                  new Date(ele.startDate),
                  new Date(ele.endDate),
                  ele?.recordStatus,
                  ele?.status,
                  ele?.emergencyContact,
                  ele?.dateOfOnBoarding ? new Date(ele.dateOfOnBoarding) : "-"
                );
                traineeMasterData.push(emptyArray);
              });
              var traineeMaster = XLSX.utils.aoa_to_sheet(traineeMasterData, {
                dateNF: "dd-mmm-yy",
                cellDates: true,
              });
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, traineeMaster, "Trainee Master");
              hideLoadingIndicator();
              XLSX.writeFile(wb, `${inseedBatchName}_Trainee_report.xlsx`);
            }
          }
        }
      )
    );
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();
      dispatch(
        actions.ModuleBulkUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              hideLoadingIndicator();
              const uploadedCount = res.meta_data.uploadedRecordsCount;
              const faultRecordsCount = res.meta_data.faultRecordsCount;
              if (faultRecordsCount > 0) {
                var BasicInfoData = [
                  [
                    "Batch Name",
                    "Trainee Name",
                    "DOJ-INSEED(YYYY-MM-DD)",
                    "LWD-INSEED(YYYY-MM-DD)",
                    "Personal Email",
                    "Contact Number",
                    "Emergency Contact Number",
                    "On Boarding Date(YYYY-MM-DD)",
                  ],
                ];
                res.data?.forEach((ele: any) => {
                  let emptyArray: any = [];
                  emptyArray.push(
                    ele.inseedBatchName,
                    ele.resourceName,
                    new Date(ele.startDate),
                    new Date(ele.endDate),
                    ele.personalEmail,
                    ele.contactNumber,
                    ele.emergencyContact,
                    new Date(ele.dateOfOnBoarding)
                  );
                  BasicInfoData.push(emptyArray);
                });
                var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData, {
                  dateNF: "mm-ddd-yy",
                  cellDates: true,
                });
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, BasicInfo, "Trainee Master");

                XLSX.writeFile(wb, "Fault_Records_TraineeMaster.xlsx");
              }
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              if (uploadedCount > 0 && faultRecordsCount === 0)
                showSuccessToastMessage("Uploaded Successfully");
              if (faultRecordsCount > 0 && uploadedCount > 0)
                showSuccessToastMessage(
                  `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount > 0)
                showErrorToastMessage(
                  `Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount === 0)
                showErrorToastMessage("Please Upload Valid Document");
            }
          }
        )
      );
    }
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const [allRecordsValue, setAllRecordsValue] = useState("");
  const handleFilterByStatus = (value: any) => {
    setOnclickSearch(true);
    if (value == "All Records") {
      setAllRecordsValue(value);
      setFilterValue([]);
      setSearchValue("");
    } else {
      setFilterValue([{ title: "Record Status", value: "status" }]);
      setSearchValue(value);
      setAllRecordsValue("");
    }
    setPage(1);
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{trainee_master}</h2>
          </div>
          {isInseedMaker && !(selectedInseedRecordStatus == "Completed") && (
            <div className="btn-wrapper">
              <Button
                title="+ Add Trainee"
                onClick={handleAddInseedResource}
                className="add-employee-btn"
              />
            </div>
          )}
        </div>
        <div className="row"></div>

        <div className="btn-wrapper mt-3"></div>

        <div className="trainee-master-table-container">
          <div className="trainee-master-searchbar-wrapper">
            <div className="searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>

            <div className="trainee-master-filter-container">
              <select
                id="select-filters"
                value={typeof filterValue === "string" ? filterValue : ""}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = trainingMasterFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {trainingMasterFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="buttonsize select-batch-wrapper">
              <select
                value={selectedInseedBatchId}
                onChange={(e: any) => {
                  const selectedBatch = inseedBatches.find(
                    (obj: any) => obj._id == e.target.value
                  );
                  setInseedBatchStartDate(selectedBatch.startDate);
                  setinseedBatchId(
                    selectedBatch._id + "&" + selectedBatch?.recordStatus
                  );
                  setInseedBatchName(selectedBatch.inseedBatch);
                  setPage(1);
                }}
              >
                <option hidden>{select_by_batch}</option>
                {inseedBatches.map((item: any, index: any) => (
                  <option key={index} value={item._id}>
                    {item.inseedBatch}
                  </option>
                ))}
              </select>
            </div>

            <div className="buttonsize select-batch-wrapper">
              <select
                value={allRecordsValue ? filter_by_record_status : searchValue}
                onChange={(e: any) => handleFilterByStatus(e.target.value)}
              >
                <option hidden>{filter_by_record_status}</option>
                {options.map((item: any, index: any) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="export-report-wrapper">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  <>
                    <div className="export-btn">
                      <button onClick={generateXLFile}>
                        <img src={export_report} alt="icon" /> {exportReport}
                      </button>
                    </div>
                  </>
                  {isInseedMaker && (
                    <>
                      <div className="export-btn">
                        <a
                          href="https://management-spotlight.sgp1.digitaloceanspaces.com/inseed-training/training-plans/fb5610ec-daac-4973-a96d-c98b8eea1d56.csv"
                          download={"tempfile.csv"}
                          style={{ textDecoration: "none" }}
                        >
                          <button>
                            <img src={template_icon} alt="icon" /> {template}
                          </button>
                        </a>
                      </div>
                      <div className="export-btn">
                        <button>
                          <img src={bulk_upload_icon} alt="icon" />{" "}
                          {bulk_upload}
                        </button>
                        <input
                          type="file"
                          accept={".csv"}
                          onChange={handleBulkUpload}
                          id="bulk_upload"
                          ref={fileInputRef}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />

          {data?.length > 0 ? (
            <>
              <div className="table-responsive">
                <Table className="table-margin">
                  <thead>
                    <tr className="custom-table-head-row">
                      {columns.map((ele: any) => (
                        <React.Fragment key={ele.Header}>
                          <td
                            onClick={() => sorting(ele.accessor)}
                            className="sorting-cursor"
                          >
                            {ele.Header}
                            {ele.Header !== "Action" &&
                            ele.Header !== "" &&
                            ele.Header != "Remark" ? (
                              <span>{<FaSort />}</span>
                            ) : (
                              " "
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="employeedata training-master-table">
                    {data?.length === 0 ? (
                      <tr>
                        <td colSpan={12}> {}</td>
                      </tr>
                    ) : (
                      (isSorted ? sortedData : data)
                        .filter((project: any) =>
                          globalFilter
                            ? project?.Employee[0]?.candidateName
                                ?.toLowerCase()
                                .includes(globalFilter)
                            : project
                        )
                        ?.slice(pageVisited, pageVisited + pagePerSize)
                        .map((ele: any, index: any) => (
                          <React.Fragment key={ele?._id}>
                            <tr>
                              <td
                                onClick={() => handleExpansion(index)}
                                className="text-start"
                              >
                                <img
                                  src={arrow_drop_down}
                                  className={
                                    index === rowid
                                      ? expansion
                                        ? "rotate-icon"
                                        : ""
                                      : ""
                                  }
                                  alt="icon"
                                />
                                <span className="ms-3">
                                  {ele?.resourceName}
                                </span>
                              </td>
                              <td>{ele?.contactNumber}</td>
                              <td>{ele?.personalEmail}</td>
                              <td>{timeDateFormat(ele.startDate)}</td>
                              <td>{timeDateFormat(ele.endDate)}</td>
                              <td>{ele?.recordStatus}</td>
                              <td>{ele?.status}</td>
                              {(isInseedMaker || isInseedChecker) && (
                                <td>
                                  <div className="action-btn">
                                    <img
                                      src={icons.edit_blue_icon}
                                      alt="edit_icon"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      className="edit-icon"
                                      onClick={() => handleEdit(ele)}
                                    />
                                    <img
                                      src={icons.delete_icon}
                                      alt="delete-icon"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => handleDelete(ele?._id)}
                                      className="edit-icon"
                                    />
                                  </div>
                                </td>
                              )}
                            </tr>
                            {index === rowid &&
                              expansion &&
                              (ele?.length === 0 ? (
                                <tr>
                                  <td colSpan={11}> {no_data_found}</td>
                                </tr>
                              ) : (
                                <tr key={ele?._id}>
                                  <td colSpan={12}>
                                    <div className="d-flex justify-content-around">
                                      <div>
                                        <div className="invoice_heading">
                                          {emergency_contact_no}
                                        </div>
                                        <div>{ele?.emergencyContact}</div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {on_boarding_date}
                                        </div>
                                        <div>
                                          {ele.dateOfOnBoarding
                                            ? timeDateFormat(
                                                ele.dateOfOnBoarding
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {certificate}
                                        </div>
                                        <div>
                                          {ele.degreeCertificate
                                            ?.document_path_url ? (
                                            <a
                                              href={
                                                ele.degreeCertificate
                                                  ?.document_path_url
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={description}
                                                alt="icon"
                                              />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {agreement_letter}
                                        </div>
                                        <div>
                                          {ele.bondLetter?.document_path_url ? (
                                            <a
                                              href={
                                                ele.bondLetter
                                                  ?.document_path_url
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={description}
                                                alt="icon"
                                              />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {offer_letter}
                                        </div>
                                        <div>
                                          {ele.offerLetter
                                            ?.document_path_url ? (
                                            <a
                                              href={
                                                ele.offerLetter
                                                  ?.document_path_url
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={description}
                                                alt="icon"
                                              />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </React.Fragment>
                        ))
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pagePerSize}
                  setPageSize={setPagePerSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <p className="no-data">{no_data_found}</p>
          )}
        </div>
      </MainCard>

      <ModalComponents
        show={showAddInseedResource}
        onHide={hideAddModal}
        title={"Add Inseed Trainee"}
        modalBody={
          <AddInseedResource
            hideAddModal={hideAddModal}
            apiCallCompleted={apiCallCompleted}
            inseedBatchStartDate={inseedBatchStartDate}
            setInseedBatchStartDate={setInseedBatchStartDate}
          />
        }
        className="modal-lg"
      />
      <ModalComponents
        show={showEditInseedResource}
        onHide={hideEditModal}
        title={"Edit Inseed Trainee"}
        modalBody={
          <EditInseedResource
            resourceDetails={resourceDetails}
            hideEditModal={hideEditModal}
            apiCallCompleted={apiCallCompleted}
            inseedBatchStartDate={inseedBatchStartDate}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteInseedResource}
        onHide={hideDeleteModal}
        title="Delete Inseed Trainee"
        modalBody={
          <DeleteInseedResource
            id={deleteId}
            hideDeleteModal={hideDeleteModal}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default ResourceMaster;
