import { useEffect, useState, useMemo, useRef } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import Button from "../../../../component/Buttons/Button";
import SearchBar from "../../../../component/SearchBar/SearchBar";
import AddEmployee from "../../EmployeeDbFunctions/AddEmployee";
import DeleteEmployee from "../../EmployeeDbFunctions/DeleteEmployee";
import EditEmployee from "../../EmployeeDbFunctions/EditEmployee";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "../../../../assets/index";
import "../../EmployeeDb.scss";
import * as actions from "../../../../Redux/Actions";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import InActiveEmployee from "../../EmployeeDbFunctions/InActiveEmployee";
import useLoadingIndicator from "../../../../component/Loader/Loader";
import ShowEntries from "../../../../component/ShowEntries/ShowEntries";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import * as XLSX from "xlsx";
import Profile from "./Profile";
import {
  BasicInfoData,
  BasicInfoHeader,
  certificationsData,
  educationalQualificationsData,
  employeeDbFilters,
  employementHistoryData,
  skillsData,
  Strings,
  SummaryInfoData,
  workAchData,
} from "../../../../constant/Strings";
import TablePagination from "../../../../component/TablePagination/TablePagination";
import FilterValues from "../../../../component/FieldFilter/FilterValues";
import {
  formatDate,
  timeDateFormat,
  timeDateFormatYMD,
} from "../../../../component/FormatDate";

export default function Employeedb(props: any) {
  const pathname = window.location.pathname;
  const id = pathname?.split("/").pop();
  const [profileId, setProfileId] = useState(id);
  const [profileInfo, setProfileInfo] = useState(true);
  const [personalInfoTab, setPersonalInfoTab] = useState(false);
  const [contactInfoTab, setContactInfoTab] = useState(false);
  const [employeeIdentificationTab, setemployeeIdentificationTab] =
    useState(false);
  const [financialInfoTab, setFinancialInfoTab] = useState(false);
  const [educationalInfo, setEducationalInfo] = useState(false);
  const [employmentHistory, setEmploymentHistory] = useState(false);
  const [skills, setSkills] = useState(false);
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const role = localStorage.getItem("role");
  const employeeDbMaker = role && role.includes("employee-db-maker");
  const employeeDbChecker = role && role.includes("employee-db-checker");
  const employeeDbHrManager = role && role.includes("employee-db-hrmanager");
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    pagePerSize,
    setPagePerSize,
    totalEntries,
    info,
    onSearchClicked,
    filterKey,
  } = props;
  const [deleteId, setDeleteId] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showExportReport, setShowExportReport] = useState(false);
  const [profileDataReceived, setProfileDataReceived] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({
    id: "",
    title: "",
    candidateName: "",
    middleName: "",
    lastname: "",
    emailId: "",
    mobileNumber: "",
    designation: "",
    dateOfJoining: "",
    recordStatus: "",
    candidatePhoto: "",
    employeeId: "",
    dateOfBirth: "",
    reportingManagerName: "",
    status: "",
    lastWorkingDate: "",
    reason: "",
    managerRef: "",
    reasonForResignation: "",
    resentDate: "",
  });

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const { hamburger_icon, export_report, template_icon, bulk_upload_icon } =
    icons;
  const {
    exportReport,
    template,
    bulk_upload,
    approved_label,
    draft_label,
    updated_label,
    resent_label,
    republished_label,
    published_label,
    new_label,
    resigned_label,
    absconded_label,
    employeeManagement,
    noDataFound,
    profileTitle,
    Filters,
    exportReportInactive,
    exportReportActive,
  } = Strings;
  const handleAddModalShow = () => {
    setShowAddEmployee(true);
  };
  const hideAddModal = () => {
    setShowAddEmployee(false);
  };

  const dispatch = useDispatch();

  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const handleEditEmployee = (
    id: string,
    title: any,
    candidateName: any,
    middleName: any,
    lastname: any,
    email: any,
    mobno: any,
    desig: any,
    startdate: any,
    recordStatus: any,
    candidatePhoto: any,
    dateOfBirth: any,
    employeeId: any,
    reportingManagerName: any,
    status: any,
    lastWorkingDate: any,
    reason: any,
    managerRef: any,
    reasonForResignation: any,
    resentDate: any
  ) => {
    setEmployeeDetails({
      id: id,
      title: title,
      candidateName: candidateName,
      middleName: middleName,
      lastname: lastname,
      emailId: email,
      mobileNumber: mobno,
      designation: desig,
      dateOfJoining: startdate,
      recordStatus: recordStatus,
      candidatePhoto: candidatePhoto,
      dateOfBirth: dateOfBirth,
      employeeId: employeeId,
      reportingManagerName: reportingManagerName,
      status: status,
      lastWorkingDate: lastWorkingDate,
      reason: reason,
      managerRef: managerRef,
      reasonForResignation: reasonForResignation,
      resentDate: resentDate,
    });
    setShowEditEmployee(true);
  };

  const hideEditModal = () => {
    setShowEditEmployee(false);
  };

  const [showDeleteEmployee, setShowDeleteEmployee] = useState(false);
  const handleDeleteEmployee = (id: any) => {
    setDeleteId(id);
    setShowDeleteEmployee(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteEmployee(false);
  };
  const [activeId, setActiveId] = useState("");
  const [showInactiveEmployee, setShowInactiveEmployee] = useState(false);
  const hideInactiveModal = () => {
    setShowInactiveEmployee(false);
  };

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeePagData
  );

  const myRef = useRef(null);
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  const executeScroll = (myRef: any) => {
    myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const handleEmployeeDetails = (selectedId: any) => {
    dispatch(actions.updateSelectedIdAction(selectedId));
    dispatch(
      actions.getemployeeDetailsAction(selectedId, (res: any) => {
        setProfileDataReceived(true);
      })
    );
    setProfileInfo(true);
    setContactInfoTab(false);
    setemployeeIdentificationTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setPersonalInfoTab(false);
    executeScroll(myRef);
    setTimeout(() => {
      localStorage.removeItem("fromSearch");
    }, 1000);
  };
  const COLUMNS: any = [
    {
      Header: "E-Code",
      accessor: "employeeId",
    },
    {
      Header: "Employee Name",
      accessor: "candidateName",
      Cell: (Action: any) => {
        const name = `${
          Action.row.original.middleName
            ? Action.row.original.candidateName +
              " " +
              Action.row.original.middleName +
              " " +
              Action.row.original.lastname
            : Action.row.original.candidateName +
              " " +
              Action.row.original.lastname
        } `;
        const selectedId = Action.row.original._id;
        return (
          <div
            className="hyperlink"
            onClick={() => handleEmployeeDetails(selectedId)}
          >
            {name}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "emailId",
      width: "100%",
    },
    {
      Header: "Contact",
      accessor: "mobileNumber",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "Date of Joining",
      accessor: "dateOfJoining",
      Cell: (Action: any) => {
        const joiningdate = Action.row.original.dateOfJoining;
        return timeDateFormat(joiningdate);
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (Action: any) => {
        return (
          <button
            type="button"
            className={
              Action.value === "Active"
                ? "btn employeedb-status-btn employee-active-btn"
                : Action.value === "Inactive"
                ? "btn employeedb-status-btn employee-inactive-btn"
                : Action.value === "New"
                ? "btn employeedb-status-btn employee-new-btn"
                : ""
            }
          >
            {Action.value}
          </button>
        );
      },
    },

    {
      Header: "Details Status",
      accessor: "recordStatus",
      Cell: (Action: any) => {
        return (
          <button
            type="button"
            className={`employeedb-record-status-btn ${
              Action.value === approved_label
                ? "btn employee-active-btn"
                : Action.value === draft_label
                ? "btn employee-draft-btn"
                : Action.value === updated_label
                ? "btn employee-active-btn"
                : Action.value === resent_label
                ? "btn employee-resent-btn"
                : Action.value === republished_label
                ? "btn employee-republished-btn"
                : Action.value === published_label
                ? "btn employee-published-btn"
                : Action.value === new_label
                ? "btn employee-new-btn"
                : Action.value === resigned_label
                ? "btn employee-inactive-btn"
                : Action.value === absconded_label
                ? "btn employee-inactive-btn"
                : "btn"
            }`}
          >
            {Action.value == "Re-Published" ? "Republished" : Action.value}
          </button>
        );
      },
    },
    {
      Header: "Project mapped Status ",
      accessor: "projectMappedStatus",
      Cell: (Action: any) => {
        return (
          <button
            type="button"
            className={`employeedb-record-status-btn ${
              Action.value === "Mapped"
                ? "btn employee-active-btn"
                : Action.value === "Pending"
                ? "btn employee-republished-btn"
                : ""
            }`}
          >
            {Action.value}
          </button>
        );
      },
    },
  ];

  const makerCheckerColumns = [
    ...COLUMNS,
    {
      Header: "Action",
      disableSortBy: false,
      Cell: (Action: any) => {
        const id = Action.row.original._id;
        const candidateName = Action.row.original.candidateName;
        const middleName = Action.row.original.middleName;
        const lastname = Action.row.original.lastname;
        const email = Action.row.original.emailId;
        const mobno = Action.row.original.mobileNumber;
        const desig = Action.row.original.designation;
        const startdate = timeDateFormatYMD(Action.row.original.dateOfJoining);
        const recordStatus = Action.row.original.recordStatus;
        const candidatePhoto = Action.row.original.candidatePhoto;
        const title = Action.row.original.title;
        const dateOfBirth = timeDateFormatYMD(Action.row.original.dateOfBirth);
        const lastWorkingDate = Action.row.original.lastWorkingDate
          ? timeDateFormatYMD(Action.row.original.lastWorkingDate)
          : "";
        const employeeId = Action.row.original.employeeId;
        const status = Action.row.original.status;
        const managerRef = Action.row.original.managerRef;
        const reportingManagerName = Action.row.original.Manager[0]
          ? Action.row.original.Manager[0]?.candidateName +
            " " +
            Action.row.original.Manager[0]?.middleName +
            " " +
            Action.row.original.Manager[0]?.lastname +
            "-" +
            Action.row.original.Manager[0]?.employeeId
          : "";
        const reason = Action.row.original.reason;
        const reasonForResignation = Action.row.original.reasonForResignation;
        const resentDate = Action.row.original.resentDate;
        return (
          <>
            <div className="action-btn">
              <img
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                src={icons.edit_blue_icon}
                alt="edit_icon"
                className="edit-icon"
                onClick={() =>
                  handleEditEmployee(
                    id,
                    title,
                    candidateName,
                    middleName,
                    lastname,
                    email,
                    mobno,
                    desig,
                    startdate,
                    recordStatus,
                    candidatePhoto,
                    dateOfBirth,
                    employeeId,
                    reportingManagerName,
                    status,
                    lastWorkingDate,
                    reason,
                    managerRef,
                    reasonForResignation,
                    resentDate
                  )
                }
              />
            </div>
          </>
        );
      },
    },
  ];

  const data = info;
  const columns = useMemo(
    () =>
      employeeDbMaker || employeeDbChecker || employeeDbHrManager
        ? makerCheckerColumns
        : COLUMNS,
    [pagePerSize, employeeDbChecker, employeeDbHrManager, employeeDbMaker]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagePerSize,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function getMergeCells(data: any) {
    const mergeCells = [];
    let startRow = 0;
    let currentCode = data[0][1];
    for (let i = 0; i <= data.length; i++) {
      if (i < data.length && data[i][1] === currentCode) {
        continue;
      }
      mergeCells.push({ s: { r: startRow, c: 0 }, e: { r: i - 1, c: 0 } });
      mergeCells.push({ s: { r: startRow, c: 1 }, e: { r: i - 1, c: 1 } });
      if (i < data.length) {
        startRow = i;
        currentCode = data[i][1];
      }
    }
    return mergeCells;
  }
  const generateXLFile = (type: string) => {
    showLoadingIndicator();
    dispatch(
      actions.getEmployeeDataPaginationAction(
        totalEntries,
        1,
        filterKey,
        searchValue,
        (res: any) => {
          if (res.code === 200) {
            if (res?.data.length > 0) {
              let filterData;
              if (type === "active") {
                filterData = res?.data?.filter(
                  (employee: any) => employee.status != "Inactive"
                );
              } else {
                filterData = res?.data?.filter(
                  (employee: any) => employee.status === "Inactive"
                );
              }
              const BasicInfoData: any = [BasicInfoHeader];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                BasicInfoData.push([
                  candidateName,
                  candidateID,
                  ele.convertedDateOfJoining
                    ? formatDate(ele.convertedDateOfJoining)
                    : "",
                  ele.emailId,
                  ele?.personalMail,
                  ele.mobileNumber,
                  ele.alternateNumber,
                  ele.aadharNumber,
                  ele.pan,
                  ele.dateOfBirth ? formatDate(ele.dateOfBirth) : "",
                  ele.bloodGroup,
                  ele.passportNumber,
                  ele.designation,
                  ele.department,
                  ele.level,
                  ele.band,
                  ele?.previousExperience,
                  ele?.organisationExperience,
                  ele?.totalExperience,
                  ele?.currentLocation,
                  ele.clientName,
                  ele.workLocation,
                  ele?.Manager[0]
                    ? ele?.Manager[0]?.candidateName +
                      " " +
                      ele?.Manager[0]?.middleName +
                      " " +
                      ele?.Manager[0]?.lastname
                    : "",
                  ele?.Manager[0] ? ele?.Manager[0]?.employeeId : "",
                  ele?.Hrbp[0]
                    ? ele?.Hrbp[0]?.candidateName +
                      " " +
                      ele?.Hrbp[0]?.middleName +
                      " " +
                      ele?.Hrbp[0]?.lastname
                    : "",
                  ele?.Hrbp[0] ? ele?.Hrbp[0]?.employeeId : "",
                  ele.pfNumber,
                  ele.uanNumber,
                  ele.insuranceNumber,
                  (ele.currentAddress1 ? ele.currentAddress1 : "") +
                    " " +
                    (ele.currentAddress2 ? ele.currentAddress2 : "") +
                    " " +
                    (ele.currentAddress3 ? ele.currentAddress3 : ""),
                  ele.currentCountry,
                  ele.currentDistrict,
                  ele.currentPinCode,
                  ele.currentState,
                  (ele.permanentAddress1 ? ele.permanentAddress1 : "") +
                    " " +
                    (ele.permanentAddress2 ? ele.permanentAddress2 : "") +
                    " " +
                    (ele.permanentAddress3 ? ele.permanentAddress3 : ""),
                  ele.permanentCountry,
                  ele.permanentDistrict,
                  ele.permanentPinCode,
                  ele.permanentState,
                  ele.bankName,
                  ele.accountNumber,
                  ele.accountHolderName,
                  ele.ifscCode,
                  ele.status,
                  ele.recordStatus,
                  ele.lastWorkingDate ? formatDate(ele.lastWorkingDate) : "",
                  ele.reasonForResignation,
                  ele.reason,
                  ele.resentDate ? formatDate(ele.resentDate) : "",
                  ele.projectMappedStatus,
                ]);
              });
              const BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData, {
                dateNF: "dd-mmm-yy",
                cellDates: true,
              });
              let summaryHeader: any = [SummaryInfoData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                summaryHeader.push([
                  candidateName,
                  candidateID,
                  ele.summaryReminderCount === 0
                    ? ""
                    : ele.summaryReminderCount ?? "",
                  ele.summaryReminderStatus ?? "",
                  ele?.summary
                    ?.map((item: any) => "• " + item.summary)
                    .join("\n"),
                  ele.employeeSummaryStatus,
                ]);
              });
              let SummaryInfo = XLSX.utils.aoa_to_sheet(summaryHeader);
              let mergeEduQual: any = [];
              let eduQualHeader: any = [educationalQualificationsData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                ele.qualifications?.forEach((element: any) => {
                  eduQualHeader.push([
                    candidateName,
                    candidateID,
                    element.qualification,
                    element.branch,
                    element.institute,
                    element.board,
                    element.year,
                    element.marks,
                  ]);
                });
              });
              mergeEduQual = mergeEduQual.concat(getMergeCells(eduQualHeader));
              let educationalQualifications =
                XLSX.utils.aoa_to_sheet(eduQualHeader);
              educationalQualifications["!merges"] = mergeEduQual.map(
                (range: any) => ({
                  s: { r: range.s.r, c: range.s.c },
                  e: { r: range.e.r, c: range.e.c },
                })
              );

              let mergeWorkAch: any = [];
              let workAchHeader: any = [workAchData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                ele.achievements?.forEach((element: any) => {
                  workAchHeader.push([
                    candidateName,
                    candidateID,
                    element.award,
                    element.description,
                    element.date ? formatDate(element.date) : "",
                  ]);
                });
              });
              mergeWorkAch = mergeWorkAch.concat(getMergeCells(workAchHeader));
              let workAch = XLSX.utils.aoa_to_sheet(workAchHeader, {
                dateNF: "dd-mmm-yy",
                cellDates: true,
              });
              workAch["!merges"] = mergeWorkAch.map((range: any) => ({
                s: { r: range.s.r, c: range.s.c },
                e: { r: range.e.r, c: range.e.c },
              }));
              let mergeSkills: any = [];
              let skillsHeader: any = [skillsData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                ele.skills?.forEach((element: any) => {
                  skillsHeader.push([
                    candidateName,
                    candidateID,
                    element.isTechnical == "Yes"
                      ? "Technical"
                      : element.isTechnical == "No"
                      ? "Non Technical"
                      : "",
                    element.skillName,
                    element.description,
                    element.proficiencylevel,
                  ]);
                });
              });

              mergeSkills = mergeSkills.concat(getMergeCells(skillsHeader));
              let skills = XLSX.utils.aoa_to_sheet(skillsHeader);
              skills["!merges"] = mergeSkills.map((range: any) => ({
                s: { r: range.s.r, c: range.s.c },
                e: { r: range.e.r, c: range.e.c },
              }));
              let mergeEmpHistory: any = [];
              let empHistoryHeader: any = [employementHistoryData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                ele.experiences?.forEach((element: any) => {
                  empHistoryHeader.push([
                    candidateName,
                    candidateID,
                    element.companyName,
                    element.designation,
                    element.location,
                    element.rolesAndResponsibility,
                    element.startDate ? formatDate(element.startDate) : "",
                    element.endDate ? formatDate(element.endDate) : "",
                  ]);
                });
              });
              mergeEmpHistory = mergeEmpHistory.concat(
                getMergeCells(empHistoryHeader)
              );
              let employementHistory = XLSX.utils.aoa_to_sheet(
                empHistoryHeader,
                {
                  dateNF: "dd-mmm-yy",
                  cellDates: true,
                }
              );
              employementHistory["!merges"] = mergeEmpHistory.map(
                (range: any) => ({
                  s: { r: range.s.r, c: range.s.c },
                  e: { r: range.e.r, c: range.e.c },
                })
              );

              let mergeCertifications: any = [];
              let certificationsHeader: any = [certificationsData];
              filterData.forEach((ele: any) => {
                let candidateName =
                  ele.candidateName +
                  " " +
                  (ele.middleName ? ele.middleName : "") +
                  " " +
                  ele.lastname;
                let candidateID = ele.employeeId;
                ele.certifications?.forEach((element: any) => {
                  certificationsHeader.push([
                    candidateName,
                    candidateID,
                    element.nameOfCertification,
                    element.institute,
                    element.board,
                    element.year,
                    element.marks,
                  ]);
                });
              });
              mergeCertifications = mergeCertifications.concat(
                getMergeCells(certificationsHeader)
              );
              let certifications =
                XLSX.utils.aoa_to_sheet(certificationsHeader);
              certifications["!merges"] = mergeCertifications.map(
                (range: any) => ({
                  s: { r: range.s.r, c: range.s.c },
                  e: { r: range.e.r, c: range.e.c },
                })
              );
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(
                wb,
                BasicInfo,
                "Basic Employee Details"
              );
              XLSX.utils.book_append_sheet(
                wb,
                educationalQualifications,
                "Educational Qualifications"
              );
              XLSX.utils.book_append_sheet(
                wb,
                certifications,
                "Certifications"
              );
              XLSX.utils.book_append_sheet(
                wb,
                employementHistory,
                "Employement History"
              );
              XLSX.utils.book_append_sheet(wb, workAch, "Work Achievements");
              XLSX.utils.book_append_sheet(wb, skills, "Skills");
              XLSX.utils.book_append_sheet(wb, SummaryInfo, "Profile Summary");
              hideLoadingIndicator();
              if (type === "active") {
                XLSX.writeFile(wb, "Employees_active_report.xlsx");
              } else {
                XLSX.writeFile(wb, "Employees_Inactive_report.xlsx");
              }
            }
          }
        }
      )
    );
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();
      dispatch(
        actions.EmployeeBulkUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              hideLoadingIndicator();
            } else if (res?.code === 500) {
              hideLoadingIndicator();
              showErrorToastMessage("something went wrong !!");
            } else if (res?.code === 200) {
              hideLoadingIndicator();
              const uploadedCount = res?.meta_data?.uploadedRecordsCount;
              const faultRecordsCount = res?.meta_data?.faultRecordsCount;
              if (faultRecordsCount > 0) {
                var BasicInfoData = [
                  [
                    "Title",
                    "First Name",
                    "Middle Name",
                    "Last Name",
                    "Employee Id",
                    "Date of Joining",
                    "Official Email",
                    "Personal Email",
                    "Mobile Number",
                    "Alternate Number",
                    "Emergency Number",
                    "Aadhar Number",
                    "PAN Number",
                    "Date Of Birth",
                    "Blood Group",
                    "Passport Number",
                    "Designation/Role",
                    "Department",
                    "Level",
                    "Band",
                    "Client Name",
                    "Work Location",
                    "Reporting Manager Name",
                    "PF Number",
                    "Insurance Number",
                    "UAN Number",
                    "Current Address",
                    "Current Country",
                    "Current State",
                    "Current District",
                    "Current Pincode",
                    "Permanent Address",
                    "Permanent Country",
                    "Permanent State",
                    "Permanent District",
                    "Permanent Pincode",
                    "Hrbp Code",
                    "Current Location",
                    "Bank Name",
                    "Account Number",
                    "Account Holder Name",
                    "IFSC Code",
                    "Skill Name",
                    "Years Of Experience",
                    "Is Technical(Yes/No)",
                    "Proficiency Level",
                    "Details Status",
                    "Last Working Date",
                  ],
                ];
                res.data?.forEach((ele: any) => {
                  let emptyArray: any = [];
                  emptyArray.push(
                    ele.title,
                    ele.candidateName,
                    ele.middleName,
                    ele.lastname,
                    ele.employeeId,
                    ele.dateOfJoining ? formatDate(ele.dateOfJoining) : "",
                    ele.emailId,
                    ele.personalMail,
                    ele.mobileNumber,
                    ele.alternateNumber,
                    ele.emergencyContactNumber,
                    ele.aadharNumber,
                    ele.pan,
                    ele.dateOfBirth ? formatDate(ele.dateOfBirth) : "",
                    ele.bloodGroup,
                    ele.passportNumber,
                    ele.designation,
                    ele.department,
                    ele.level,
                    ele.band,
                    ele.experience,
                    ele.clientName,
                    ele.workLocation,
                    ele.reportingManagerName,
                    ele.pfNumber,
                    ele.insuranceNumber,
                    ele.uanNumber,
                    ele.currentAddress1,
                    ele.currentCountry,
                    ele.currentState,
                    ele.currentDistrict,
                    ele.currentPinCode,
                    ele.permanentAddress1,
                    ele.permanentCountry,
                    ele.permanentState,
                    ele.permanentDistrict,
                    ele.permanentPinCode,
                    ele.hrbpCode,
                    ele.currentLocation,
                    ele.bankName,
                    ele.accountNumber,
                    ele.accountHolderName,
                    ele.ifscCode,
                    ele.skillName,
                    ele.experience,
                    ele.isTechnical,
                    ele.proficiencylevel,
                    ele.recordStatus,
                    ele.lastWorkingDate ? formatDate(ele.lastWorkingDate) : ""
                  );
                  BasicInfoData.push(emptyArray);
                });
                var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData, {
                  dateNF: "mm-ddd-yy",
                  cellDates: true,
                });
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                  wb,
                  BasicInfo,
                  "Basic Employee Details"
                );

                XLSX.writeFile(wb, "Fault_Records.xlsx");
              }
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              if (uploadedCount > 0 && faultRecordsCount === 0)
                showSuccessToastMessage("Uploaded Successfully");
              if (faultRecordsCount > 0 && uploadedCount > 0)
                showSuccessToastMessage(
                  `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount > 0)
                showErrorToastMessage(
                  `Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount === 0)
                showErrorToastMessage("Please Upload Valid Document");
            }
          }
        )
      );
    }
  };
  useEffect(() => {
    if (employeeData && employeeData.data) {
      setTotalRecords(employeeData.data.length);
    }
  }, [employeeData, info, data, pagePerSize, pageSize]);

  useEffect(() => {
    if (profileId) {
      handleEmployeeDetails(profileId);
    }
  }, [data, profileDataReceived, profileId]);

  return (
    <>
      {loadingComponent()}
      <div className="heading-button-wrapper" key={profileId}>
        <div>
          <h2>{employeeManagement}</h2>
        </div>
        {employeeDbMaker && (
          <div className="btn-wrapper">
            <Button
              title="+ Add Employee"
              onClick={handleAddModalShow}
              className="add-employee-btn"
            />
          </div>
        )}
      </div>

      <div className="mt-2">
        <div className="mt-3 buttonsize filter-wrapper">
          <div className="emp-searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>

          <div className="emp-filter-container">
            <select
              id="select-filters"
              value={typeof filterValue === "string" ? filterValue : ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = employeeDbFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{Filters}</option>
              {employeeDbFilters.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="export-report-wrapper">
            <img
              ref={imgIcon}
              src={hamburger_icon}
              onClick={() => setShowExportReport(!showExportReport)}
              alt="icon"
              height={30}
              width={30}
            />
            {showExportReport && (
              <div className="report-btn-wrapper" ref={exportReportRef}>
                <div className="export-btn">
                  <button
                    onClick={() => generateXLFile("active")}
                    className="width-btn"
                  >
                    <img src={export_report} alt="icon" /> {exportReportActive}
                  </button>
                </div>
                <div className="export-btn width-btn">
                  <button
                    onClick={() => generateXLFile("inactive")}
                    className="width-btn"
                  >
                    <img src={export_report} alt="icon" />{" "}
                    {exportReportInactive}
                  </button>
                </div>
                {employeeDbMaker && (
                  <>
                    <div className="export-btn">
                      <a
                        href="https://management-spotlight.sgp1.digitaloceanspaces.com/spotlight/EmployeeDB_Bulk_Upload_Template3.csv"
                        download={"tempfile.csv"}
                        style={{ textDecoration: "none" }}
                      >
                        <button>
                          <img src={template_icon} alt="icon" /> {template}
                        </button>
                      </a>
                    </div>
                    <div className="export-btn">
                      <button>
                        <img src={bulk_upload_icon} alt="icon" /> {bulk_upload}
                      </button>
                      <input
                        type="file"
                        accept={".csv"}
                        onChange={handleBulkUpload}
                        id="bulk_upload"
                        ref={fileInputRef}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />

        {data.length > 0 ? (
          <>
            <div className="table table-responsive">
              <table
                className="table-main table table-sm mt-3"
                {...getTableProps()}
              >
                <thead className="table-head">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="table-head-row"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column: any) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="table-body" {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr className="table-body-row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="employeedata">{noDataFound}</p>
        )}
      </div>

      <ModalComponents
        show={showAddEmployee}
        onHide={hideAddModal}
        title="Add Employee Details"
        modalBody={
          <AddEmployee
            apiCallCompleted={apiCallCompleted}
            hideAddModal={hideAddModal}
            showLoadingIndicator={showLoadingIndicator}
            totalEntries={totalEntries}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditEmployee}
        onHide={hideEditModal}
        title="Edit Employee Details"
        modalBody={
          <EditEmployee
            apiCallCompleted={apiCallCompleted}
            id={employeeDetails.id}
            candidateName={employeeDetails.candidateName}
            middleName={employeeDetails.middleName}
            lastname={employeeDetails.lastname}
            email={employeeDetails.emailId}
            mobileNumber={employeeDetails.mobileNumber}
            designation={employeeDetails.designation}
            dateOfJoining={employeeDetails.dateOfJoining}
            recordStatus={employeeDetails.recordStatus}
            candidatePhoto={employeeDetails.candidatePhoto}
            title={employeeDetails.title}
            dateOfBirth={employeeDetails.dateOfBirth}
            employeeId={employeeDetails.employeeId}
            eId={employeeDetails.id}
            managerRef={employeeDetails.managerRef}
            reportingManagerName={employeeDetails.reportingManagerName}
            statusData={employeeDetails.status}
            lastWorkingDate={employeeDetails.lastWorkingDate}
            reason={employeeDetails?.reason}
            showEditEmployee={handleEditEmployee}
            hideEditModal={hideEditModal}
            totalEntries={totalEntries}
            reasonForResignation={employeeDetails.reasonForResignation}
            resentDate={employeeDetails.resentDate}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteEmployee}
        onHide={hideDeleteModal}
        title="Delete Employee "
        modalBody={
          <DeleteEmployee
            id={deleteId}
            showDeleteEmployee={handleDeleteEmployee}
            hideDeleteModal={hideDeleteModal}
          />
        }
        className="modal-sm"
      />

      <ModalComponents
        show={showInactiveEmployee}
        onHide={hideInactiveModal}
        title="Inactive Employee "
        modalBody={
          <InActiveEmployee
            apiCallCompleted={apiCallCompleted}
            id={activeId}
            hideInactiveModal={hideInactiveModal}
          />
        }
        className="modal-sm"
      />

      <div className="employee-details" ref={myRef}></div>
      <div className="heading-button-wrapper mb-5 mt-5 ms-4">
        <h2>{profileTitle}</h2>
      </div>
      <Profile
        profileId={profileId}
        profileInfo={profileInfo}
        setProfileInfo={setProfileInfo}
        personalInfoTab={personalInfoTab}
        setPersonalInfoTab={setPersonalInfoTab}
        contactInfoTab={contactInfoTab}
        setContactInfoTab={setContactInfoTab}
        employeeIdentificationTab={employeeIdentificationTab}
        setemployeeIdentificationTab={setemployeeIdentificationTab}
        financialInfoTab={financialInfoTab}
        setFinancialInfoTab={setFinancialInfoTab}
        educationalInfo={educationalInfo}
        setEducationalInfo={setEducationalInfo}
        employmentHistory={employmentHistory}
        setEmploymentHistory={setEmploymentHistory}
        skills={skills}
        setSkills={setSkills}
      />
    </>
  );
}
