import { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import MainCard from "../../component/MainCard/MainCard";
import "./SkillLinks.scss";
import * as actions from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  SkillLinksValidationStrings,
  Strings,
  jobCategoryDropdown,
  modeOfWorkDropdown,
  workLevelDropdown,
  workLevelArray,
  workExperienceObj,
  referalAmountObj,
  postaJobExportCol,
  employementType,
} from "../../constant/Strings";
import { icons } from "../../assets";
import {
  experienceValidation,
  jobTitleValidation,
  locationValidation,
  referralAmountValidation,
  workLevelValidation,
} from "../../constant/Validations";
import useLoadingIndicator from "../../component/Loader/Loader";
import { AiOutlineCheck } from "react-icons/ai";

const PostaJob = () => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [btnDisable, setBtnDisable] = useState(false);
  const [jobs, setJobs] = useState<any>({
    jobTitle: "",
    jobCategory: "",
    modeOfWork: "",
    experience: "",
    location: "",
    workLevel: "",
    referralAmount: "",
    description: "",
    uploadJobDescription: "",
    salaryRange: "",
    ijp: 0,
    majorSkills: [],
    employmentType:""
  });
  const {
    jobTitle_error,
    jobCategory_error,
    location_error,
    modeOfWork_error,
    experience_error,
    workLevel_error,
    referralAmount_error,
    ctc_range_error,
    ctc_greater_error,
    ctc_number_error
  } = SkillLinksValidationStrings;
  const {
    exp_4_10_years,
    exp_7_11_years,
    exp_8_15_years,
    exp_10_20_years,
    exp_12_20_years,
    exp_15_20_years,
    exp_15_30_years,
  } = workExperienceObj;
  const [docFile, setDocFile] = useState<any>({
    uploadJobDescription: null,
    bulkUpload: null,
  });
  const { referral5000, referral15000, referral25000 } = referalAmountObj;
  const [showExportReport, setShowExportReport] = useState(false);
  const {
    addSuccess,
    bulk_upload,
    template,
    post_a_job,
    job_title,
    job_category,
    work_mode,
    employment_type,
    enter_location,
    work_level,
    upload_description,
    description,
    major_skills,
    cancel,
    submit,
    upload,
    imageSize2mb,
    experience,
    location_label,
    referral_amount,
    sr_associate,
    lead,
    manager,
    sr_manager,
    avp,
    vp,
    sr_vp,
    majorSkills_valid,
    ctc_range,
  } = Strings;
  const { hamburger_icon, bulk_upload_icon, template_icon } = icons;
  const [locationOption,setLocationOption]= useState([]);
  const [lacationValue,setLocationValue]= useState("");

  const dispatch = useDispatch();
  const handleClearForm = () => {
    setJobs({
      jobTitle: "",
      jobCategory: "",
      modeOfWork: "",
      experience: "",
      location: "",
      workLevel: "",
      referralAmount: "",
      description: "",
      uploadJobDescription: "",
      majorSkills: [],
      ijp: 0,
      salaryRange: "",
    });
  };
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const unAuthorizeUser: any = role && !role.includes("skill-link-maker");
  useEffect(() => {
    if (unAuthorizeUser) {
      navigate("/");
    }
  }, []);
  let valid: boolean = true;
  const validate = () => {
    if (jobTitleValidation(jobs.jobTitle) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: jobTitle_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: "",
      }));
    }
    if (jobTitleValidation(jobs.modeOfWork) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: modeOfWork_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: "",
      }));
    }
    if (jobTitleValidation(jobs.jobCategory) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: jobCategory_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: "",
      }));
    }
    if (experienceValidation(jobs.experience) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        experience: experience_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        experience: "",
      }));
    }
    if (locationValidation(jobs.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    if (workLevelValidation(jobs.workLevel) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: workLevel_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: "",
      }));
    }
    if (referralAmountValidation(jobs.referralAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        referralAmount: referralAmount_error,
      }));
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        referralAmount: "",
      }));
    }
    if (
      jobs.salaryRange === "" || 
      isNaN(Number(jobs.salaryRange)) || 
      Number(jobs.salaryRange) < 1200000
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        salaryRange: jobs.salaryRange === "" 
          ? ctc_range_error 
          : isNaN(Number(jobs.salaryRange)) 
          ? ctc_number_error
          : ctc_greater_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        salaryRange: "",
      }));
    }
    
  };
  const handleJobsSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    dispatch(
      actions.addPostAJobAction(jobs, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else if (res?.code === 200) {
            showSuccessToastMessage(addSuccess);
            setJobs({
              jobTitle: "",
              jobCategory: "",
              modeOfWork: "",
              experience: "",
              location: "",
              workLevel: "",
              referralAmount: "",
              description: "",
              uploadJobDescription: "",
              majorSkills: [],
              ijp: 0,
              salaryRange: "",
            });
            setDocFile({
              ...docFile,
              uploadJobDescription: "",
            });
            hideLoadingIndicator();
          }
      })
    );
    handleClearForm();
  };
  const addJobCategory = useSelector(
    (state: any) => state.employeeReducer.addJobCategoryDetails
  );
  useEffect(() => {
    dispatch(actions.getJobCategoryAction());
  }, [addJobCategory]);

  const [category, setCategory] = useState([]);
  const getJobCategory = useSelector(
    (state: any) => state.employeeReducer.getJobCategoryDetails
  );
  useEffect(() => {
    if (getJobCategory) {
      if (getJobCategory.data) {
        setCategory(getJobCategory.data);
      }
    }
  }, [getJobCategory, category]);

  const [errors, setErrors] = useState({
    jobTitle: "",
    jobCategory: "",
    modeOfWork: "",
    experience: "",
    location: "",
    workLevel: "",
    referralAmount: "",
    description: "",
    salaryRange: "",
  });

  useEffect(() => {
    if (
      jobs.jobTitle &&
      jobs.modeOfWork &&
      jobs.jobCategory &&
      jobs.experience &&
      jobs.location &&
      jobs.workLevel &&
      jobs.referralAmount &&
      jobs.salaryRange &&
      (jobs.description || jobs.uploadJobDescription) &&
      jobs.majorSkills?.length > 0 &&
      jobs.employmentType
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [jobs]);


  const handleJobCancel = () => {
    window.location.reload();
  };
  const handleJobDescriptionSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (!["application/pdf"].includes(fileType)) {
      showErrorToastMessage("File type not allowed. Allowed type is PDF.");
      return;
    } else {
      setDocFile({ ...docFile, uploadJobDescription: e.target.files[0] });
      dispatch(
        actions.jobDescriptionUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setJobs({
                ...jobs,
                uploadJobDescription: res.data,
              });
            }
          }
        )
      );
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();
      if (e.target.files[0]?.type == "text/csv") {
        dispatch(
          actions.BulkUploadAction({ file: e.target.files[0] }, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res?.code === 200) {
                hideLoadingIndicator();
                const uploadedCount = res?.meta_data?.uploadedRecordsCount;
                const faultRecordsCount = res?.meta_data?.faultRecordsCount;
                if (faultRecordsCount > 0) {
                  var BasicInfoData = [postaJobExportCol];
                  res.data?.forEach((ele: any) => {
                    let emptyArray: string[] = [];
                    emptyArray.push(
                      ele.jobTitle,
                      ele.employmentType,
                      ele.jobCategory,
                      ele.modeOfWork,
                      ele.experience,
                      ele.location,
                      ele.workLevel,
                      ele.referralAmount,
                      ele.salaryRange,
                      ele.description,
                    );
                    BasicInfoData.push(emptyArray);
                  });
                  var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, BasicInfo, post_a_job);
                  XLSX.writeFile(wb, "Fault_Records_Post_Job.xlsx");
                }
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                if (uploadedCount > 0 && faultRecordsCount === 0)
                  showSuccessToastMessage("Uploaded Successfully");
                if (faultRecordsCount > 0 && uploadedCount > 0)
                  showSuccessToastMessage(
                    `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount > 0)
                  showErrorToastMessage(
                    `Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount === 0)
                  showErrorToastMessage("Please Upload Valid Document");
              }
            }
          })
        );
      } else {
        hideLoadingIndicator();
        showErrorToastMessage("Please Upload .csv File");
      }
    }
  };
  const handleWorkLevelChange = (eventKey: any) => {
    workLevelArray.forEach((element: any) => {
      if (element == eventKey) {
        if (eventKey == sr_associate) {
          setJobs({
            ...jobs,
            experience: exp_4_10_years,
            workLevel: eventKey,
            referralAmount: referral5000,
          });
        }
        if (eventKey == lead) {
          setJobs({
            ...jobs,
            experience: exp_7_11_years,
            workLevel: eventKey,
            referralAmount: referral15000,
          });
        }
        if (eventKey == manager) {
          setJobs({
            ...jobs,
            experience: exp_8_15_years,
            workLevel: eventKey,
            referralAmount: referral15000,
          });
        }
        if (eventKey == sr_manager) {
          setJobs({
            ...jobs,
            experience: exp_10_20_years,
            workLevel: eventKey,
            referralAmount: referral15000,
          });
        }
        if (eventKey == avp) {
          setJobs({
            ...jobs,
            experience: exp_12_20_years,
            workLevel: eventKey,
            referralAmount: referral25000,
          });
        }
        if (eventKey == vp) {
          setJobs({
            ...jobs,
            experience: exp_15_20_years,
            workLevel: eventKey,
            referralAmount: referral25000,
          });
        }
        if (eventKey == sr_vp) {
          setJobs({
            ...jobs,
            experience: exp_15_30_years,
            workLevel: eventKey,
            referralAmount: referral25000,
          });
        }
      }
    });
  };

  const handleLocation = (eventKey: any) => {
    setJobs({
      ...jobs,
      location: eventKey,
    });
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);

  const handleOtherLocation = () => {
                    const payload = {
                      status: 1,
                      workLocationName: lacationValue
                    }
                    dispatch(
                      actions.addWorkLocationAction(payload, (res: any) => {
                         if (res?.error?.error_description) {
                           setLocationValue("");
                              showErrorToastMessage(res.error.error_description);
                            } else if(res?.code === 200) {
                              showSuccessToastMessage(addSuccess);
                              setLocationValue("");
                              getWorkLocationApi()
                        }
                      })
                    );
  }
    const getWorkLocationApi =() => {
      dispatch(
        actions.GetWorkLocationAction((res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if(res?.code === 200) {
                setLocationOption(res.data);
          }
        })
      );
    }

    useEffect(() => {
      getWorkLocationApi()
    }, []);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{post_a_job}</h2>
          </div>
          <div className="btn-wrapper">
            <div className="export-report-wrapper-skillLinks">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="hamburger_icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  <div className="export-btn">
                    <a
                      href="https://management-spotlight.sgp1.digitaloceanspaces.com/spotlight/post-a-job.csv"
                      download="Post_Job_Bulk_Upload_Template.csv"
                    >
                      <button>
                        <img src={template_icon} alt="template icon" />{" "}
                        {template}
                      </button>
                    </a>
                  </div>
                  <div className="export-btn">
                    <button>
                      <img src={bulk_upload_icon} alt="bulk upload icon" />{" "}
                      {bulk_upload}
                    </button>
                    <input
                      type="file"
                      accept={".csv"}
                      onChange={handleBulkUpload}
                      id="bulk_upload"
                      ref={fileInputRef}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card mt-2">
          <form className="row g-3 m-2">
            <div className="col-md-4">
              <label className="form-label">{job_title}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                value={jobs.jobTitle}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    jobTitle: e.target.value,
                  })
                }
              />
              <p className="error">
                {jobs.jobTitle.length !== 0 && errors.jobTitle}
              </p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{employment_type}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setJobs({
                    ...jobs,
                    employmentType: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.employmentType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {employementType.map((value: any) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{work_mode}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setJobs({
                    ...jobs,
                    modeOfWork: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.modeOfWork}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {modeOfWorkDropdown.map((value: any) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{job_category}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setJobs({
                    ...jobs,
                    jobCategory: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.jobCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {jobCategoryDropdown.map((value: any) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{location_label}</label>
              <span className="required">*</span>
   <Dropdown
           onSelect={(e) => {
            handleLocation(e);
          }}
          >
            <Dropdown.Toggle
              variant="light"
              className="form-control training-location-dropdown"
            >
              {jobs.location ? jobs.location : "Select Location"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="technolgyAdd">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform" style={{width:"85%"}}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        value={lacationValue}
                        onChange={(e) =>
                          setLocationValue(e.target.value)
                        }
                        id="technolgyAdd"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          lacationValue.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleOtherLocation}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.location}</p>
                  </div>
                </Form.Group>
              </Form>
              {locationOption?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.workLocationName}>
                  {ele.workLocationName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
              <p className="error">
                {jobs.location.length !== 0 && errors.location}
              </p>
            </div>
          

            <div className="col-md-4">
              <label className="form-label">{work_level}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) => {
                  handleWorkLevelChange(eventKey);
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.workLevel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {workLevelDropdown.map((value: any) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <p className="error">
                {jobs.workLevel.length !== 0 && errors.workLevel}
              </p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{experience}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="5 to 10 years"
                value={jobs.experience}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    experience: e.target.value,
                  })
                }
                disabled
              />
              <p className="error">
                {jobs.experience.length !== 0 && errors.experience}
              </p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{referral_amount}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Amount in Rupees"
                maxLength={5}
                value={jobs.referralAmount}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    referralAmount: e.target.value,
                  })
                }
              />
              <p className="error">
                {jobs.referralAmount.length !== 0 && errors.referralAmount}
              </p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{major_skills}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Max. 5 major skills seperated with comma"
                value={jobs.majorSkills}
                onChange={(e) => {
                  const skillArray = e.target.value
                    .split(",")
                    .map((skill: any) => skill);
                  const updatedMajorSkills =
                    skillArray.length === 1 && skillArray[0] === ""
                      ? []
                      : skillArray;
                  setJobs({
                    ...jobs,
                    majorSkills: updatedMajorSkills,
                  });
                }}
              />
              <p className="error">
                {jobs.majorSkills?.length > 5 && majorSkills_valid}
              </p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{ctc_range}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="CTC Range"
                value={jobs.salaryRange}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    salaryRange: e.target.value,
                  })
                }
              />
              <p className="error">
                {jobs.salaryRange.length !== 0 && errors.salaryRange}
              </p>
            </div>
            {!jobs.uploadJobDescription && (
              <div className="col-md-8">
                <label className="form-label">{description}</label>
                <span className="required">*</span>
                <textarea
                  className="form-control text-height"
                  placeholder="Please copy paste the entire JD including location experience, skillset required."
                  value={jobs.description}
                  disabled={jobs.uploadJobDescription}
                  onChange={(e) =>
                    setJobs({
                      ...jobs,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {!jobs.description && (
              <div className="col-md-4">
                <label className="form-label">{upload_description}</label>
                <span className="required">*</span>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    value={jobs.uploadJobDescription?.name}
                    disabled={jobs.description}
                    onChange={handleJobDescriptionSubmit}
                    hidden
                    id="actual-btn-uploadJob"
                  />
                  <label
                    htmlFor="actual-btn-uploadJob"
                    className="upload-jd-button upload-width"
                  >
                    {upload}
                  </label>
                </div>
                <p className="image-size-condition buttonsize">
                  {imageSize2mb}
                  <span className="required">*</span>
                </p>
                <a
                  href={jobs.uploadJobDescription?.document_path_url}
                  target="_blank"
                  className="buttonsize"
                >
                  {jobs.uploadJobDescription?.document_path_url
                    ? "View File"
                    : ""}
                </a>
                <p className="image-size-condition">
                  {docFile.uploadJobDescription?.name}
                </p>
              </div>
            )}
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={handleJobCancel}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className={
                    btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                  onClick={handleJobsSubmit}
                >
                  {submit}
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainCard>
    </>
  );
};

export default PostaJob;
