import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import "./ResourceAllocation.scss";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { icons } from "../../../assets/index";
import { Link } from "react-router-dom";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import * as XLSX from "xlsx";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import {
  projectRepoFilters,
  Strings,
  resourceAllocationsTableColumn,
  resourceAllocationTotalExportReport,
  AllResource,
} from "../../../constant/Strings";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import MainCard from "../../../component/MainCard/MainCard";
import useLoadingIndicator from "../../../component/Loader/Loader";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import { formatDate, timeDateFormat } from "../../../component/FormatDate";
import { Table } from "react-bootstrap";
const ResourceAllocation = (props: any) => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { showLoadingIndicator, hideLoadingIndicator, loadingComponent } =
    useLoadingIndicator();
  const [page, setPage] = useState<Number>(1);
  const role = localStorage.getItem("role");
  const isMakerData = role?.includes("resource-maker");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalEntries, setTotalEntries] = useState<any>();
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [data, setdata] = useState<any>([]);
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
        localStorage.removeItem("filterData");
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
    if (value == "") {
      localStorage.removeItem("filterData");
    }
  };

  const columns = useMemo(() => resourceAllocationsTableColumn, []);
  const {
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const bulkUpload = useSelector(
    (state: any) => state.employeeReducer.resourceBulkUploadDetails
  );
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const oldFiter = JSON.parse(localStorage.getItem("filterData") || "[]");
  const key = oldFiter?.[0];
  const value = oldFiter?.[1];

  const filterKey = filterValue?.length
    ? filterValue.map((ele: any) => ele.value)
    : key
    ? key
    : [];
  const search = searchValue || value || "";
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getResourceAllocationApprovedAction(
          pageSize,
          page,
          filterKey,
          search,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [page, filterValue, searchValue, "", pageSize, condition, bulkUpload]);
  useEffect(() => {
    const oldFilter = JSON.parse(localStorage.getItem("filterData") || "[]");

    const keys = oldFilter?.[0];
    const value = oldFilter?.[1];

    if (Array.isArray(keys) && keys.length > 0 && value) {
      const selectedOptions = keys
        .map((key) => projectRepoFilters.find((item) => item.value === key))
        .filter(Boolean);

      if (selectedOptions.length > 0) {
        setFilterValue(selectedOptions);
      }
    }
  }, [projectRepoFilters]);
  const projectsDetails = useSelector(
    (state: any) => state.employeeReducer.getApprovedProjectDetails
  );

  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (projectsDetails && projectsDetails.data) {
      setdata(projectsDetails.data);
      setTotalRecords(projectsDetails.data.length);
    }
  }, [projectsDetails, data]);

  const [showExportReport, setShowExportReport] = useState(false);
  const dispatch = useDispatch();

  const fileInputRef1 = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();

      dispatch(
        actions.ResourceBulkUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              hideLoadingIndicator();
              const uploadedCount = res?.meta_data?.uploadedRecordsCount;
              const faultRecordsCount = res.meta_data?.faultRecordsCount;
              if (faultRecordsCount > 0) {
                var BasicInfoData = [
                  [
                    "E - code",
                    "Sub Project Code",
                    "Start Date(YYYY-MM-DD)",
                    "Estimated End Date(YYYY-MM-DD)",
                    "Role Description",
                    "Utilizztion(%)",
                    "Purchase Order No",
                    "PO Start Date(YYYY-MM-DD)",
                    "PO End Date(YYYY-MM-DD)",
                    "Currency Type",
                    "Billing Status",
                    "Billing Rate",
                    "Remarks",
                  ],
                ];
                res.data?.forEach((ele: any) => {
                  let emptyArray: any = [];
                  emptyArray.push(
                    ele.employeeCode,
                    ele.subProjectCode,
                    formatDate(ele.startDate),
                    formatDate(ele.endDate),
                    ele.roleDescription,
                    ele.utilization,
                    ele.poNumber,
                    formatDate(ele.poStartDate),
                    formatDate(ele.poEndDate),
                    ele.currencyType,
                    ele.billingRate,
                    ele.poAmount,
                    ele.remarks
                  );
                  BasicInfoData.push(emptyArray);
                });
                var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                  wb,
                  BasicInfo,
                  "Resource Allocation Details"
                );

                XLSX.writeFile(wb, "Fault_Records_ResourceAllocation.xlsx");
              }
              if (fileInputRef1.current) {
                fileInputRef1.current.value = "";
              }
              if (uploadedCount > 0 && faultRecordsCount === 0)
                showSuccessToastMessage("Uploaded Successfully");
              if (faultRecordsCount > 0 && uploadedCount > 0)
                showSuccessToastMessage(
                  `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount > 0)
                showErrorToastMessage(
                  `Downloaded ${faultRecordsCount} Fault Records`
                );
              if (uploadedCount === 0 && faultRecordsCount === 0)
                showErrorToastMessage("Please Upload Valid Document");
            }
          }
        )
      );
    }
  };
  const {
    hamburger_icon,
    export_report,
    template_icon,
    bulk_upload_icon,
    arrow_drop_down,
  } = icons;
  const {
    exportReport,
    template,
    bulk_upload,
    resource_allocation,
    projectRepo,
  } = Strings;
  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getResourceAllocationApprovedAction(
        totalEntries,
        1,
        filterKey,
        searchValue,
        (res: any) => {
          if (res.code === 200 && res?.data.length > 0) {
            var moduleMasterData = [resourceAllocationTotalExportReport];
            res?.data?.forEach((item: any) => {
              const approvedInfo = item?.approvedByInfo
                ? item?.approvedByInfo[0]
                : "";
              const approvedByName = item?.approvedByInfo
                ? (approvedInfo?.candidateName
                    ? approvedInfo?.candidateName
                    : "") +
                  " " +
                  (approvedInfo?.middleName ? approvedInfo?.middleName : "") +
                  " " +
                  (approvedInfo?.lastname ? approvedInfo?.lastname : "")
                : "";
              const approvedById = item.approvedByInfo
                ? approvedInfo?.employeeId
                : "";
              let emptyArray: any = [];
              emptyArray.push(
                item.geography,
                item.Client.map((ele: { clientName: any }) => ele.clientName),
                item.projectName,
                item.projectType,
                item.projectCode,
                item.subProjectCode,
                item.techType,
                (item?.buHeadData[0]?.candidateName || "") +
                  " " +
                  (item?.buHeadData[0]?.middleName || "") +
                  " " +
                  (item?.buHeadData[0]?.lastname || ""),
                (item?.hrbpData[0]?.candidateName || "") +
                  " " +
                  (item?.hrbpData[0]?.middleName || "") +
                  " " +
                  (item?.hrbpData[0]?.lastname || ""),
                item.projectStartDate ? formatDate(item.projectStartDate) : "",
                item.projectEndDate ? formatDate(item.projectEndDate) : "",
                item.status,
                item.poNumber,
                item.poStartDate ? formatDate(item.poStartDate) : "",
                item.poEndDate ? formatDate(item.poEndDate) : "",
                item.approvedHc,
                item.poValueIn,
                item.billingRate,
                item.poAmount,
                item.spocPersonName,
                item.spocNumber,
                item.spocEmail,
                item?.recordStatus,
                approvedByName,
                approvedById,
                item?.approvedDate ? formatDate(item?.approvedDate) : "",
                item.remarks
              );
              moduleMasterData.push(emptyArray);
            });
            var projectRepository = XLSX.utils.aoa_to_sheet(moduleMasterData, {
              dateNF: "dd-mmm-yy",
              cellDates: true,
            });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, projectRepository, projectRepo);
            hideLoadingIndicator();
            XLSX.writeFile(wb, "Resource_Allocation.xlsx");
          }
        }
      )
    );
  };

  const generateXLFileAllResource = () => {
    showLoadingIndicator();
    dispatch(
      actions.getAllResourceExportAction((res: any) => {
        if (res.code === 200 && res?.data.length > 0) {
          var activeResourceData = [AllResource];
          var inactiveResourceData = [AllResource];

          res?.data?.forEach((item: any) => {
            const employeeData =
              item?.employeeData?.length > 0 ? item?.employeeData[0] : "";
            const resourceName =
              item?.employeeData?.length > 0
                ? (employeeData?.candidateName
                    ? employeeData?.candidateName
                    : "") +
                  " " +
                  (employeeData?.middleName ? employeeData?.middleName : "") +
                  " " +
                  (employeeData?.lastname ? employeeData?.lastname : "")
                : "";

            const clientName =
              item?.projectData?.length > 0 &&
              item?.projectData[0]?.clientData?.length > 0
                ? item?.projectData[0]?.clientData[0].clientName
                : "";

            let rowData: any = [];
            rowData.push(
              item?.projectData?.length > 0
                ? item?.projectData[0]?.subProjectCode
                : "",
              item?.employeeData?.length > 0
                ? item?.employeeData[0]?.employeeId
                : "",
              resourceName,
              item?.employeeData?.length > 0
                ? formatDate(item?.employeeData?.[0]?.dateOfJoining)
                : "",
              item?.employeeData?.[0]?.department,
              clientName,
              item?.projectData?.[0]?.projectCode,
              (item?.employeeData[0]?.resourceHrbpData[0]?.candidateName ||
                "") +
                " " +
                (item?.employeeData[0]?.resourceHrbpData[0]?.middleName || "") +
                " " +
                (item?.employeeData[0]?.resourceHrbpData[0]?.lastname || ""),

              (item?.employeeData[0]?.resourceManagerData[0]?.candidateName ||
                "") +
                " " +
                (item?.employeeData[0]?.resourceManagerData[0]?.middleName ||
                  "") +
                " " +
                (item?.employeeData[0]?.resourceManagerData[0]?.lastname || ""),
              (item?.projectData?.[0]?.buHeadData?.[0]?.candidateName || "") +
                " " +
                (item?.projectData?.[0]?.buHeadData?.[0]?.middleName || "") +
                " " +
                (item?.projectData?.[0]?.buHeadData?.[0]?.lastname || ""),
              item.startDate ? formatDate(item.startDate) : "",
              item.endDate ? formatDate(item.endDate) : "",
              item.poAmount,
              item.recordStatus,
              item?.employeeData?.length > 0
                ? item?.employeeData[0]?.status
                : "",

              item.createdByData?.length
                ? item?.createdByData[0]?.candidateName +
                    " " +
                    item?.createdByData[0]?.middleName +
                    " " +
                    item?.createdByData[0]?.lastname
                : "",
              item.createdAt ? formatDate(item.createdAt) : "",
              item.updatedByData?.length
                ? item?.updatedByData[0]?.candidateName +
                    " " +
                    item?.updatedByData[0]?.middleName +
                    " " +
                    item?.updatedByData[0]?.lastname
                : "",
              item.updatedAt ? formatDate(item.updatedAt) : ""
            );

            if (item?.employeeData[0]?.status === "Inactive") {
              inactiveResourceData.push(rowData);
            } else {
              activeResourceData.push(rowData);
            }
          });

          var activeSheet = XLSX.utils.aoa_to_sheet(activeResourceData, {
            dateNF: "dd-mmm-yy",
            cellDates: true,
          });
          var inactiveSheet = XLSX.utils.aoa_to_sheet(inactiveResourceData, {
            dateNF: "dd-mmm-yy",
            cellDates: true,
          });

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, activeSheet, "Active Resources");
          XLSX.utils.book_append_sheet(wb, inactiveSheet, "Inactive Resources");

          hideLoadingIndicator();
          XLSX.writeFile(wb, "Resource_Report.xlsx");
        }
      })
    );
  };

  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{resource_allocation}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div className="mt-3 buttonsize resource-allocation-filter-wrapper ">
            <div className="searchbar-wrapper">
              <SearchBar
                filter={value ? value : searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="filter-container">
              <select
                id="select-filters"
                value={typeof filterValue === "string" ? filterValue : ""}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = projectRepoFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {projectRepoFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="export-report-wrapper">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="hamburger_icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  {
                    <>
                      <div className="export-btn">
                        <button onClick={generateXLFile}>
                          <img src={export_report} alt="export_report" />
                          {exportReport}
                        </button>
                      </div>
                    </>
                  }
                  {
                    <>
                      <div className="export-btn">
                        <button onClick={() => generateXLFileAllResource()}>
                          <img src={export_report} alt="export_report" />
                          Export All Resource
                        </button>
                      </div>
                    </>
                  }
                  {isMakerData && (
                    <>
                      <div className="export-btn">
                        <a
                          href="https://management-spotlight.sgp1.digitaloceanspaces.com/spotlight/Resource_Allocation_Bulk_Edit_Template2.csv"
                          download={"Resource-allocation-template.csv"}
                        >
                          <button>
                            <img src={template_icon} alt="template_icon" />{" "}
                            {template}
                          </button>
                        </a>
                      </div>
                      <div className="export-btn">
                        <label htmlFor="file_upload">
                          <button onClick={handleButtonClick}>
                            <img
                              src={bulk_upload_icon}
                              alt="bulk_upload_icon"
                            />{" "}
                            {bulk_upload}
                          </button>
                        </label>
                        <input
                          type="file"
                          accept={".csv"}
                          onChange={(e) => {
                            handleBulkUpload(e);
                          }}
                          id="file_upload"
                          ref={fileInputRef1}
                          style={{ display: "none" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data.length > 0 ? (
            <>
              <div className="table table-responsive">
                <Table className="table-margin">
                  <thead>
                    <tr className="custom-table-head-row">
                      {columns.map((ele: any) => (
                        <React.Fragment key={ele.Header}>
                          <td className="sorting-cursor">{ele.Header}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="employeedata">
                    {data?.length === 0 ? (
                      <tr>
                        <td colSpan={12}>No Data Found</td>
                      </tr>
                    ) : (
                      data.map((ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td
                              onClick={() => handleExpansion(index)}
                              className="text-start"
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <img
                                  src={arrow_drop_down}
                                  className={
                                    index === rowid
                                      ? expansion
                                        ? "rotate-icon"
                                        : ""
                                      : ""
                                  }
                                  alt="icon"
                                />
                                <span>&nbsp;{ele?.geography}</span>
                              </div>
                            </td>
                            <td
                              onClick={() => {
                                const data = [filterKey, searchValue];
                                localStorage.setItem(
                                  "filterData",
                                  JSON.stringify(data)
                                );
                                localStorage.setItem(
                                  "routeType",
                                  "ResourceAllocation"
                                );
                              }}
                            >
                              <Link to={`/resource-adding/${ele._id}`}>
                                {ele?.Client[0].clientName}
                              </Link>
                            </td>
                            <td>{ele?.projectDescription}</td>
                            <td>{ele?.projectType}</td>
                            <td>{ele?.subProjectCode}</td>
                            <td>
                              {(ele?.buHeadData[0]?.candidateName || "") +
                                " " +
                                (ele?.buHeadData[0]?.middleName || "") +
                                " " +
                                (ele?.buHeadData[0]?.lastname || "")}
                            </td>
                            <td>
                              {(ele?.hrbpData[0]?.candidateName || "") +
                                " " +
                                (ele?.hrbpData[0]?.middleName || "") +
                                " " +
                                (ele?.hrbpData[0]?.lastname || "")}
                            </td>
                            <td>{ele?.actualHc}</td>
                            <td>{ele?.approvedHc}</td>
                            <td>
                              {ele?.status && (
                                <button
                                  type="button"
                                  className={
                                    ele?.status === "Ongoing"
                                      ? "btn projectRepo-status-btn projectRepo-ongoing-btn"
                                      : ele?.status === "New"
                                      ? "btn projectRepo-status-btn projectRepo-new-btn"
                                      : ele?.status === "Delivered"
                                      ? "btn projectRepo-status-btn projectRepo-delivered-btn"
                                      : ele?.status === "Closed" ||
                                        ele?.status === "Expired"
                                      ? "btn projectRepo-status-btn  projectRepo-closed-btn"
                                      : ele?.status === "On Hold"
                                      ? "btn projectRepo-status-btn projectRepo-onHold-btn"
                                      : ele?.status === "Extended"
                                      ? "btn projectRepo-status-btn projectRepo-updated-btn"
                                      : ele?.status === "No Extension"
                                      ? "btn projectRepo-status-btn projectRepo-rejected-btn"
                                      : ele?.status === "Conversion"
                                      ? "btn projectRepo-status-btn projectRepo-approved-btn"
                                      : ""
                                  }
                                >
                                  {ele?.status}
                                </button>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className={
                                  ele?.recordStatus === "New"
                                    ? "btn projectRepo-status-btn projectRepo-new-btn"
                                    : ele?.recordStatus === "Approved"
                                    ? "btn projectRepo-status-btn projectRepo-approved-btn"
                                    : ele?.recordStatus === "Rejected"
                                    ? "btn projectRepo-status-btn projectRepo-rejected-btn"
                                    : ele?.recordStatus === "Updated"
                                    ? "btn projectRepo-status-btn projectRepo-updated-btn"
                                    : ""
                                }
                              >
                                {ele?.recordStatus}
                              </button>
                            </td>
                          </tr>
                          {index === rowid && expansion && (
                            <tr>
                              <td colSpan={12}>
                                <div className="d-flex justify-content-start">
                                  <div className="mx-4">
                                    <div className="invoice_heading">
                                      Project Code
                                    </div>
                                    <div>{ele?.projectCode}</div>
                                  </div>
                                  <div className="mx-4">
                                    <div className="invoice_heading">
                                      PO Number
                                    </div>
                                    <div>{ele?.poNumber}</div>
                                  </div>
                                  <div className="mx-4">
                                    <div className="invoice_heading">
                                      Remarks
                                    </div>
                                    <div>{ele?.remarks}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />
                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <p className="no-data">No Data Found</p>
          )}
        </div>
      </MainCard>
    </>
  );
};

export default ResourceAllocation;
