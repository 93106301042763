import { useEffect, useState } from "react";
import "chart.js/auto";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { icons } from "../../assets/index";
import MainCard from "../../component/MainCard/MainCard";
import * as actions from "../../Redux/Actions/index";
import useLoadingIndicator from "../../component/Loader/Loader";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import { FaFileExcel } from "react-icons/fa";
import moment from "moment";
import * as XLSX from "xlsx";
import "./SkillCoins.scss";
import * as echarts from "echarts";
import hanldeUnauthorizedUser from "../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../component/FormatDate";
const SkillCoinsReport = (props: any) => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const [dateFilter, setDateFilter] = useState({
    fromDate: "",
    toDate: "",
  });
  const [fromDate, setFromDate] = useState(0);
  const [toDate, setToDate] = useState(0);
  const dispatch = useDispatch();
  const reportData = useSelector(
    (state: any) => state.employeeReducer.getSkillCoinsReportDetails
  );
  useEffect(() => {
    unAuthorizeUser();
    showLoadingIndicator();
    dispatch(
      actions.getSkillCoinsReportAction((res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        } else if (res.code === 200) {
          hideLoadingIndicator();
        }
      })
    );
  }, []);

  const reportDetails = reportData?.data;

  useEffect(() => {
    const data = [
      {
        name: "Total Skillcoins",
        value: reportDetails?.totalSkillCoinTransaction,
      },
      {
        name: "Allocated Skillcoins",
        value: reportDetails?.totalAllocatedTransaction,
      },
      { name: "Redeemed Skillcoins", value: reportDetails?.redeemedSkillCoins },
      {
        name: "Deallocated Skillcoins",
        value: reportDetails?.deallocatedTransaction,
      },
    ];

    const pieChart = echarts.init(document.getElementById("pieChart")!);

    const options = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      color: ["#FEDC97", "#7C9885", "#F15946", "#53B3CB"],
      legend: {
        orient: "vertical",
        left: 700,
        bottom: 150,

        data: data.map((item) => item.name),
      },
      series: [
        {
          name: "Skillcoins",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["25%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    pieChart.setOption(options);
    return () => {
      pieChart.dispose();
    };
  }, [reportDetails]);

  const [errors, setErrors] = useState({
    message: "",
  });

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;

    if (id == "from") {
      setFromDate(value);
      setDateFilter({ ...dateFilter, fromDate: value });
    }
    if (id == "to") {
      setToDate(value);
      setDateFilter({ ...dateFilter, toDate: value });
    }
  };
  let valid: boolean = true;
  const validate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();

    if (fromDate > currentYear || toDate > currentYear) {
      valid = false;
      setErrors({
        ...errors,
        message: "Year value cannot be greater than present year",
      });
    }
    if (fromDate < 2000 || toDate < 2000) {
      valid = false;
      setErrors({
        ...errors,
        message: "Year value cannot be lesser than 2000",
      });
    }
    if (fromDate > toDate) {
      valid = false;
      setErrors({
        ...errors,
        message: "From Year cannot be greater than to Year",
      });
    }
    if (!fromDate || !toDate) {
      valid = false;
      setErrors({
        ...errors,
        message: "Please enter both from year and to year",
      });
    }
    if (toDate && !fromDate) {
      valid = false;
      setErrors({
        ...errors,
        message: "Please enter from year",
      });
    }
    if (fromDate && !toDate) {
      valid = false;
      setErrors({
        ...errors,
        message: "Please enter To year",
      });
    }
  };

  let yearlyReport: any = [];
  const generateXlFile = () => {
    validate();
    if (valid) {
      dispatch(
        actions.getSkillCoinsYearlyReportAction(
          dateFilter.fromDate,
          dateFilter.toDate,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              yearlyReport = res.data;

              var skillCoinInfoData = [
                [
                  "Transaction Date",
                  "Assigned Date",
                  "E-Code",
                  "E-Name",
                  "Skill Coins Allocated",
                  "Skill Coins Deallocated",
                  "Skill Coins Redeemed",
                  "Balance Coins",
                  "Value of 1 Skill Coin",
                  "Status",
                  "Total Value",
                ],
              ];
              yearlyReport?.forEach((ele: any) => {
                let emptyArray: any = [];

                emptyArray.push(
                  new Date(ele.transactionDate),
                  new Date(
                    ele.skillCoinData && ele.skillCoinData[0]?.assigned_date
                  ),
                  ele.Employee[0]?.employeeId,
                  ele.Employee[0]?.candidateName +
                    " " +
                    ele.Employee[0]?.middleName +
                    " " +
                    ele.Employee[0]?.lastname,
                  ele.skillCoinsAllocated,
                  ele.skillCoinsDeallocated,
                  ele.skillCoinsRedeemed,
                  ele.balanceCoins,
                  ele.valueOfCoin,
                  ele.skillCoinStatus,
                  ele.totalValue
                );
                skillCoinInfoData.push(emptyArray);
              });
              var skillCoinInfo = XLSX.utils.aoa_to_sheet(skillCoinInfoData, {
                dateNF: "dd-mmm-yy",
                cellDates: true,
              });

              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(
                wb,
                skillCoinInfo,
                "Skill Coins Info"
              );
              XLSX.writeFile(wb, "Yearly Employee SkillCoin Info.xlsx");
            }
          }
        )
      );
      setErrors({ ...errors, message: "" });
    }
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="parent-section">
          <div className="left-section">
            <Link to="/skill-coins" className="back-btn">
              <img
                src={icons.backIcon}
                alt="backIcon"
                className="reporticons"
              />
              Back
            </Link>
          </div>

          <div className="right-section">
            <div className="right-input">
              <label htmlFor="inputName4" className="form-label">
                From Year <span className="required">*</span>
              </label>
              <input
                type="drop"
                id="from"
                className="form-control"
                min="2000"
                maxLength={4}
                max={moment().format("YYYY")}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
              <p className="error">{errors.message}</p>
            </div>
            <div className="right-input2">
              <label htmlFor="inputName4" className="form-label">
                To Year<span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="to"
                min="2000"
                max={moment(dateFilter.fromDate).format("YYYY")}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>

            <div className="last-section">
              <button className="button last-btn" onClick={generateXlFile}>
                <FaFileExcel /> Export Report
              </button>
            </div>
          </div>
        </div>
        <div className="skill-heading-button-wrapper">
          <h2>Skill Coins Reports</h2>
        </div>
        <div>
          <div className="report-section">
            <div className="cards-div">
              <img
                src={icons.totalSkill}
                alt="totalSkill"
                className="reporticons"
              />
              <div>
                <span className="job-desc">Total Skillcoins</span>
                <div className="total-coins-required mb-1">
                  (Allocated Coins+Redeemed Coins)
                </div>
                <h1 className="heading-number">
                  {reportDetails?.totalSkillCoinTransaction}
                </h1>
              </div>
            </div>
            <div className="cards-div">
              <img src={icons.redeem} alt="redeem" className="reporticons" />
              <div>
                <p className="job-desc">Allocated Skillcoins</p>
                <h1 className="heading-number">
                  {reportDetails?.totalAllocatedTransaction}
                </h1>
              </div>
              <div></div>
            </div>

            <div className="cards-div">
              <img
                src={icons.allocated}
                alt="allocated"
                className="reporticons"
              />
              <div>
                <p className="job-desc">Redeemed Skillcoins</p>
                <h1 className="heading-number">
                  {reportDetails?.redeemedSkillCoins}
                </h1>
              </div>
            </div>

            <div className="cards-div">
              <img
                src={icons.deallocated}
                alt="deallocated"
                className="reporticons"
              />
              <div>
                <p className="job-desc">Deallocated SkillCoins</p>
                <h1 className="heading-number">
                  {reportDetails?.deallocatedTransaction}
                </h1>
              </div>
            </div>
          </div>

          <div className="chart-section">
            <div>
              <p className="chart-heading">Employee Record Status Details</p>
            </div>
            <div className="pie-heading">
              <div id="pieChart" className="pieChart" />;
            </div>
          </div>
        </div>
      </MainCard>
    </>
  );
};

export default SkillCoinsReport;
