import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import * as actions from "../../Redux/Actions";
import CandidateRatingDesclaimer from "./CandidateRatingDesclaimer";
import {
  currentCTCValidation,
  emailIdValidation,
  employeeMobileNumberValidtion,
  locationValidation,
} from "../../constant/Validations";
import { SkillLinksValidationStrings, Strings } from "../../constant/Strings";

const AddReferResource = (props: any) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const { hideAddReferResource, jobId, apiCallCompleted } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [referResource, setReferResource] = useState<any>({
    fullName: "",
    emailId: "",
    contactNumber: "",
    location: "",
    currentCtc: "",
    noticePeriod: "",
    uploadResume: "",
    jobRef: jobId,
    employeeRef: loginId,
  });
  const [errors, setErrors] = useState({
    fullName: "",
    emailId: "",
    contactNumber: "",
    location: "",
    currentCtc: "",
    noticePeriod: "",
    uploadResume: "",
  });
  const {
    fullname_error,
    emailId_error,
    contactNumber_error,
    location_error,
    noticePeriod_error,
    currentCTC_error,
  } = SkillLinksValidationStrings;
  const {
    full_name,
    email_id,
    contact_number,
    current_ctc,
    notice_period,
    upload_resume,
    imageSize2mb,
    cancel,
    submit,
    location_label,
    upload,
    StipendNote,
    fileNote
  } = Strings;
  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (locationValidation(referResource.fullName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        fullName: fullname_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        fullName: "",
      }));
    }
    if (
      (emailIdValidation(referResource.emailId) == true &&
        referResource.emailId.includes("gmail.com")) ||
      referResource.emailId.includes("yahoo.com") ||
      referResource.emailId.includes("hotmail.com") ||
      referResource.emailId.includes("rediffmail.com") ||
      referResource.emailId.includes("live.in") ||
      referResource.emailId.includes("outlook.com")
    ) {
      setErrors((errorObj) => ({
        ...errorObj,
        emailId: "",
      }));
    } else {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        emailId: emailId_error,
      }));
      setBtnDisable(false);
    }
    if (employeeMobileNumberValidtion(referResource.contactNumber) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        contactNumber: contactNumber_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        contactNumber: "",
      }));
    }
    if (locationValidation(referResource.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    if (currentCTCValidation(referResource.currentCtc) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        currentCtc: currentCTC_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        currentCtc: "",
      }));
    }
    if (referResource.noticePeriod > 45 || referResource.noticePeriod < 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        noticePeriod: noticePeriod_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        noticePeriod: "",
      }));
    }
  };
  const handleJobsSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideAddReferResource();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addReferAResourceAction(referResource, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if(res.code === 200) {
              showSuccessToastMessage("Added Successfully!");
              apiCallCompleted(true);
            }
      })
    );
  };
  const [docFile, setDocFile] = useState<any>({
    uploadResume: null,
  });

  useEffect(() => {
    if (
      referResource.fullName &&
      referResource.emailId &&
      referResource.contactNumber &&
      referResource.location &&
      referResource.currentCtc &&
      referResource.noticePeriod &&
      referResource.uploadResume
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [referResource]);

  const handleUploadResumeSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, uploadResume: e.target.files[0] });
      dispatch(
        actions.referResourceResumeUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setReferResource({
                ...referResource,
                uploadResume: res.data,
              });
            }
          }
        )
      );
    }
  };

  return (
    <div>
      {" "}
      <div>
        <div className="row g-3">
          <div className="col-md-4">
            <label htmlFor="inputSkills" className="form-label">
              {full_name}
            </label>
            <span className="required">*</span>
            <input
              type="text"
              className="form-control"
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  fullName: e.target.value,
                })
              }
            />
            <p className="error">
              {referResource.fullName.length !== 0 && errors.fullName}
            </p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{email_id}</label>
            <span className="required">*</span>
            <input
              type="text"
              className="form-control"
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  emailId: e.target.value,
                })
              }
            />
            <p className="error">
              {referResource.emailId?.length !== 0 && errors.emailId}
            </p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{contact_number}</label>
            <span className="required">*</span>
            <input
              type="text"
              className="form-control"
              maxLength={15}
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  contactNumber: e.target.value,
                })
              }
            />
            <p className="error">
              {referResource.contactNumber.length !== 0 && errors.contactNumber}
            </p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{location_label}</label>
            <span className="required">*</span>
            <input
              type="text"
              className="form-control"
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  location: e.target.value,
                })
              }
            />
            <p className="error">
              {referResource.location.length !== 0 && errors.location}
            </p>
          </div>

          <div className="col-md-4">
            <label className="form-label">{current_ctc}</label>
            <span className="required">*</span>
            <input
              type="text"
              maxLength={9}
              className="form-control"
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  currentCtc: e.target.value,
                })
              }
            />
            <p className="error">
              {referResource.currentCtc.length !== 0 && errors.currentCtc}
            </p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{notice_period}</label>
            <span className="required">*</span>
            <input
              type="number"
              className="form-control"
              min={0}
              max={45}
              onChange={(e) =>
                setReferResource({
                  ...referResource,
                  noticePeriod: e.target.value,
                })
              }
            />
             <div className="note-text">{StipendNote}</div>
            <p className="error">{errors.noticePeriod}</p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{upload_resume}</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleUploadResumeSubmit}
                hidden
                id="actual-btn-uploadResume"
              />
              <label
                htmlFor="actual-btn-uploadResume"
                className="upload-jd-button upload-width"
              >
                {upload}
              </label>
            </div>
            <p className="image-size-condition">
              {imageSize2mb}
              <span className="required">*</span>
            </p>
            <a
              href={referResource.uploadResume?.document_path_url}
              target="_blank"
              className="buttonsize"
            >
              {referResource.uploadResume?.document_path_url ? "View File" : ""}
            </a>
            <p className="image-size-condition">{docFile.uploadResume?.name}</p>
            <div className="note-text">{fileNote}</div>
          </div>
        </div>
        <div className="desclaimer-border">
          <CandidateRatingDesclaimer />
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAddReferResource}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleJobsSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReferResource;
