import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import * as actions from "../../Redux/Actions/index";
import { Dropdown, Form } from "react-bootstrap";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import ModalWarning from "../../component/MakerChecker/ModalWarning";
import ReasonForRejection from "../../component/MakerChecker/ReasonForRejection";

import {
  experienceValidation,
  jobTitleValidation,
  locationValidation,
  workLevelValidation,
} from "../../constant/Validations";
import {
  SkillLinksValidationStrings,
  Strings,
  jobCategoryDropdown,
  locationDropdown,
  modeOfWorkDropdown,
  workLevelDropdown,
  workLevelArray,
  workExperienceObj,
} from "../../constant/Strings";
import { AiOutlineCheck } from "react-icons/ai";

const EditPostAJobIJP = (props: any) => {
  const { jobsData, hideEditReferResource, apiCallCompleted } = props;
  const [category, setCategory] = useState([]);
  const jobId = jobsData._id;
  const role = localStorage.getItem("role");
  const [btnDisable, setBtnDisable] = useState(false);
  const isCheckedData = role && role.includes("internal-job-checker");
  const [jobs, setJobs] = useState<any>({
    jobTitle: jobsData.jobTitle,
    jobCategory: jobsData.jobCategory,
    modeOfWork: jobsData.modeOfWork,
    experience: jobsData.experience,
    location: jobsData.location,
    workLevel: jobsData.workLevel,
    referralAmount: jobsData.referralAmount,
    description: jobsData.description,
    uploadJobDescription: jobsData.uploadJobDescription,
    recordStatus: "Updated",
    majorSkills: jobsData.majorSkills,
  });
  const [locationOption,setLocationOption]= useState([]);
  const [lacationValue,setLocationValue]= useState("");
  const {
    job_title,
    job_category,
    enter_location,
    work_level,
    upload_description,
    description,
    cancel,
    submit,
    upload,
    imageSize2mb,
    approve,
    reject,
    experience,
    location_label,
    mode_of_work,
    file_size_max_msg,
    file_type_msg,
    editSuccess,
    sr_associate,
    lead,
    manager,
    sr_manager,
    avp,
    vp,
    sr_vp,
    major_skills,
    addSuccess
  } = Strings;

  const {
    jobTitle_error,
    jobCategory_error,
    location_error,
    modeOfWork_error,
    experience_error,
    workLevel_error,
  } = SkillLinksValidationStrings;
  const {
    exp_4_10_years,
    exp_7_11_years,
    exp_8_15_years,
    exp_10_20_years,
    exp_12_20_years,
    exp_15_20_years,
    exp_15_30_years,
  } = workExperienceObj;
  const isLocationOther = () => {
    const locations = ["Bengaluru", "Hyderabad", "KSA", "Pune", "USA", "Other"];
    return !locations.includes(jobs.location);
  };
  const addJobCategory = useSelector(
    (state: any) => state.employeeReducer.addJobCategoryDetails
  );
  useEffect(() => {
    dispatch(actions.getJobCategoryAction());
  }, [addJobCategory]);

  const getJobCategory = useSelector(
    (state: any) => state.employeeReducer.getJobCategoryDetails
  );
  useEffect(() => {
    if (getJobCategory) {
      if (getJobCategory.data) {
        setCategory(getJobCategory.data);
      }
    }
  }, [getJobCategory, category]);
  useEffect(() => {
    if (
      jobs.jobTitle &&
      jobs.modeOfWork &&
      jobs.jobCategory &&
      jobs.experience &&
      jobs.location &&
      jobs.workLevel &&
      (jobs.description || jobs.uploadJobDescription)
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [jobs]);

  const [errors, setErrors] = useState({
    jobTitle: "",
    jobCategory: "",
    modeOfWork: "",
    experience: "",
    location: "",
    workLevel: "",
    description: "",
  });
  let valid: boolean = true;
  const validate = () => {
    if (jobTitleValidation(jobs.jobTitle) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: jobTitle_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: "",
      }));
    }
    if (jobTitleValidation(jobs.modeOfWork) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: modeOfWork_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: "",
      }));
    }
    if (jobTitleValidation(jobs.jobCategory) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: jobCategory_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: "",
      }));
    }
    if (experienceValidation(jobs.experience) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        experience: experience_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        experience: "",
      }));
    }
    if (locationValidation(jobs.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    if (workLevelValidation(jobs.workLevel) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: workLevel_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: "",
      }));
    }
  };

  const handleWorkLevelChange = (eventKey: any) => {
    workLevelArray.forEach((element: any) => {
      if (element == eventKey) {
        if (eventKey == sr_associate) {
          setJobs({
            ...jobs,
            experience: exp_4_10_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == lead) {
          setJobs({
            ...jobs,
            experience: exp_7_11_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == manager) {
          setJobs({
            ...jobs,
            experience: exp_8_15_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == sr_manager) {
          setJobs({
            ...jobs,
            experience: exp_10_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == avp) {
          setJobs({
            ...jobs,
            experience: exp_12_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == vp) {
          setJobs({
            ...jobs,
            experience: exp_15_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == sr_vp) {
          setJobs({
            ...jobs,
            experience: exp_15_30_years,
            workLevel: eventKey,
          });
        }
      }
    });
  };
  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const handleRejectModal = () => {
    setShowReasonForRejection(true);
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const [modalWarning, setModalWarning] = useState("");
  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  const [docFile, setDocFile] = useState<any>({
    uploadJobDescription: null,
  });
  const handleJobDescriptionSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (!["application/pdf"].includes(fileType)) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({ ...docFile, uploadJobDescription: e.target.files[0] });
      dispatch(
        actions.jobDescriptionDocumentUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setJobs({
                ...jobs,
                uploadJobDescription: res.data,
              });
            }
          }
        )
      );
    }
  };
  const dispatch = useDispatch();
  const [locationFeild, setLocationFeild] = useState("");
  const handleLocation = (eventKey: any) => {
    setLocationFeild(eventKey);
    setJobs({
      ...jobs,
      location: eventKey,
    });
  };

  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editIJPJobStatusAction(jobId, jobs, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if(res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
    hideEditReferResource();
  };

  const handleViewJobDesc = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.viewJobDescriptionDocAction(
        { filename: jobs.uploadJobDescription?.document_name },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };
  const handleOtherLocation = () => {
    const payload = {
      status: 1,
      workLocationName: lacationValue
    }
    dispatch(
      actions.addWorkLocationAction(payload, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              setLocationValue("");
            } else if(res?.code === 200) {
              showSuccessToastMessage(addSuccess);
              setLocationValue("");
              getWorkLocationApi()
        }
      })
    );
}
const getWorkLocationApi =() => {
dispatch(
actions.GetWorkLocationAction((res: any) => {
if (res?.error?.error_description) {
showErrorToastMessage(res.error.error_description);
} else if(res?.code === 200) {
setLocationOption(res.data);
}
})
);
}

useEffect(() => {
getWorkLocationApi()
}, []);
  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label">{job_title}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={jobs.jobTitle}
            onChange={(e) =>
              setJobs({
                ...jobs,
                jobTitle: e.target.value,
              })
            }
          />
          <p className="error">{errors.jobTitle}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{mode_of_work}</label>
          <span className="required">*</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setJobs({
                ...jobs,
                modeOfWork: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {jobs.modeOfWork}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {modeOfWorkDropdown.map((value: any) => (
                <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{job_category}</label>
          <span className="required">*</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setJobs({
                ...jobs,
                jobCategory: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {jobs.jobCategory}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {jobCategoryDropdown.map((value: any) => (
                <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{location_label}</label>
          <span className="required">*</span>

          <Dropdown
           onSelect={(e) => {
            handleLocation(e);
          }}
          >
            <Dropdown.Toggle
              variant="light"
              className="form-control training-location-dropdown"
            >
              {jobs.location ? jobs.location : "Select Location"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="technolgyAdd">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform" style={{width:"85%"}}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        value={lacationValue}
                        onChange={(e) =>
                          setLocationValue(e.target.value)
                        }
                        id="technolgyAdd"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          lacationValue.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleOtherLocation}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.location}</p>
                  </div>
                </Form.Group>
              </Form>
              {locationOption?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.workLocationName}>
                  {ele.workLocationName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <p className="error">{errors.location}</p>
        </div>
        {locationFeild == "Other" || isLocationOther() ? (
          <>
            <div className="col-md-4">
              <label className="form-label">{enter_location}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                value={isLocationOther() ? jobs.location : ""}
                placeholder="Location"
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    location: e.target.value,
                  })
                }
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="col-md-4">
          <label className="form-label">{work_level}</label>
          <span className="required">*</span>
          <Dropdown
            onSelect={(eventKey: any) => {
              handleWorkLevelChange(eventKey);
            }}
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {jobs.workLevel}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {workLevelDropdown.map((value: any) => (
                <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{experience}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="5 to 10 years"
            value={jobs.experience}
            onChange={(e) =>
              setJobs({
                ...jobs,
                experience: e.target.value,
              })
            }
            disabled
          />
          <p className="error">{errors.experience}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{major_skills}</label>
          <span className="required">*</span>
          <input
            type="text"
            value={jobs.majorSkills}
            className="form-control"
            placeholder="Max. 5 major skills seperated with comma"
            onChange={(e) => {
              setJobs({
                ...jobs,
                majorSkills: e.target.value.split(",").map((skill) => skill),
              });
            }}
          />
          <p className="error">{errors.jobTitle}</p>
        </div>
        {!jobs.uploadJobDescription && (
          <div className="col-md-8">
            <label className="form-label">{description}</label>
            <span className="required">*</span>
            <textarea
              className="form-control"
              placeholder="Please copy paste the entire JD including location experience, skillset required."
              value={jobs.description}
              onChange={(e) =>
                setJobs({
                  ...jobs,
                  description: e.target.value,
                })
              }
            />
          </div>
        )}
        {jobs.description == "" && (
          <div className="col-md-4">
            <label className="form-label">{upload_description}</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleJobDescriptionSubmit}
                hidden
                id="actual-btn-uploadJob"
              />
              <label
                htmlFor="actual-btn-uploadJob"
                className="upload-jd-button upload-width"
              >
                {upload}
              </label>
            </div>
            <p className="image-size-condition">
              {imageSize2mb}
              <span className="required">*</span>
            </p>
            <a
              href=""
              onClick={handleViewJobDesc}
              target="_blank"
              className="buttonsize"
            >
              {jobs.uploadJobDescription?.document_name ? "View File" : ""}
            </a>
            <p className="image-size-condition">
              {docFile.uploadJobDescription?.name}
            </p>
          </div>
        )}
      </div>
      <div className="row">
        {isCheckedData == false ||
        jobsData.recordStatus == "Approved" ||
        jobsData.recordStatus == "Rejected" ? (
          <></>
        ) : (
          <>
            <div className="modal-btn-wrapper mt-2 mx-auto">
              <button
                type="button"
                className="btn btn-success buttonsize"
                onClick={() => {
                  handleApproval("Approve");
                }}
              >
                {approve}
              </button>

              <button
                type="button"
                className="btn btn-danger buttonsize"
                onClick={() => {
                  handleRejectModal();
                }}
              >
                {reject}
              </button>
            </div>
          </>
        )}
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditReferResource}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleEdit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>

      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
            apiCallCompleted={apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={jobId}
            editApi={actions.editIJPJobStatusAction}
            data={{ recordStatus: modalWarning + "d" }}
            hideEditModal={hideEditReferResource}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
            apiCallCompleted={apiCallCompleted}
            hideModal={hideRejectModal}
            id={jobId}
            editApi={actions.editIJPJobStatusAction}
            editIJPJobStatusAction
            statusKey="recordStatus"
            hideEditModal={hideEditReferResource}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </div>
  );
};

export default EditPostAJobIJP;
