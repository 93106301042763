import { useEffect, useState } from "react";
import MainCard from "../../component/MainCard/MainCard";
import SearchBar from "../../component/SearchBar/SearchBar";
import ReferResourceCard from "./ReferResourceCard";
import * as actions from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import EditPostAJob from "./EditPostAJob";
import useLoadingIndicator from "../../component/Loader/Loader";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import FilterValues from "../../component/FieldFilter/FilterValues";
import ShowEntries from "../../component/ShowEntries/ShowEntries";
import TablePagination from "../../component/TablePagination/TablePagination";
import { SkillLinksFilters, Strings, ijpFilters } from "../../constant/Strings";

const ReferResource = () => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [info, setInfo] = useState([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [pageSize, setPageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(0);
  const [jobs, setJobs] = useState<any>();
  const dispatch = useDispatch();
  const [employeeInfo, setEmployeeInfo] = useState([]);

  const editReferResourceDetails = useSelector(
    (state: any) => state.employeeReducer.editSkillLinksJobStatusDetails
  );
  const { refer_resource_title, filters } = Strings;

  const role = localStorage.getItem("role");
  const skillLinksHRMakerChecker =
    role &&
    (role.includes("skill-link-maker") ||
      role.includes("skill-link-checker") ||
      role.includes("hr"));
  const { no_data_found, view_referral_policy, jdNotUploaded } = Strings;
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const filterKey = filterValue?.map((ele: any) => ele.value);
  useEffect(() => {
    if (skillLinksHRMakerChecker) {
      dispatch(
        actions.getAllJobsAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
              }
            }
          }
        )
      );
    } else {
      dispatch(
        actions.getSkillLinksEmployeeViewAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setEmployeeInfo(res.data);
                setTotalRecords(res.data && res.data?.length);
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
              }
            }
          }
        )
      );
    }
  }, [editReferResourceDetails, page, pageSize, filterValue, searchValue]);

  const allJobDetails = useSelector(
    (state: any) => state.employeeReducer.getAllJobsDetails
  );
  useEffect(() => {
    if (allJobDetails) {
      if (allJobDetails.data) {
        setInfo(allJobDetails.data);
        setTotalRecords(allJobDetails.data.length);
      }
    }
  }, [allJobDetails, info, apiCallCompleted]);

  const [policy, setPolicy] = useState<any>([]);
  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("ReferalPolicy"));
  }, []);
  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );
  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);

  const [showEditReferResource, setShowEditReferResource] = useState(false);
  const handleEditReferResource = (row: any) => {
    setJobs(row);
    setShowEditReferResource(true);
  };
  const hideEditReferResource = () => {
    setShowEditReferResource(false);
  };
  const filterChange = (selectedOption: any) => {
    setPage(1);
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const openDescURL = (e: any, jd: any) => {
    if (jd?.document_path_url) {
      e.preventDefault();
      window.open(jd?.document_path_url, "_blank");
    } else {
      showErrorToastMessage(jdNotUploaded);
    }
  };
  return (
    <div>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{refer_resource_title}</h2>
          </div>
        </div>
        <div className="view-btns-wrapper btn-wrapper">
          <button
            className="view-job-btn view-referral-list-btn"
            onClick={() =>
              window.open(policy[0]?.uploadPolicy?.document_path_url, "_blank")
            }
          >
            {view_referral_policy}
          </button>
        </div>
        <>
          <div className="mt-3 buttonsize filter-module-wrapper">
            <div className="module-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
                disabled={!filterValue?.find((obj: any) => obj.value == "All")}
              />
            </div>
            <div className="module-filter-container">
              <select
                id="select-filters"
                value={typeof filterValue === 'string' ? filterValue : ''}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = skillLinksHRMakerChecker
                    ? SkillLinksFilters.find(
                        (item: any) => item.value === selectedValue
                      )
                    : ijpFilters.find(
                        (item: any) => item.value === selectedValue
                      );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {skillLinksHRMakerChecker
                  ? SkillLinksFilters?.map((item: any, index: any) => (
                      <option key={index} value={item.value}>
                        {item.title}
                      </option>
                    ))
                  : ijpFilters?.map((item: any, index: any) => (
                      <option key={index} value={item.value}>
                        {item.title}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
        </>

        {skillLinksHRMakerChecker ? (
          info.length > 0 ? (
            info?.map((jobsData: any) => (
              <ReferResourceCard
                key={jobsData._id}
                id={jobsData._id}
                jobStatus={jobsData?.jobStatus}
                jobId={jobsData.jobId}
                jobDataId={jobsData._id}
                jobTitle={jobsData.jobTitle}
                buttonTitle={jobsData.jobStatus === 0 ? "Closed" : "Open"}
                modeOfJob={jobsData.modeOfWork}
                jobType={jobsData.jobCategory}
                postedOn={jobsData.timeSpan}
                jobDescription={jobsData?.description}
                uploadDescription={jobsData?.uploadJobDescription}
                recordStatus={jobsData?.recordStatus}
                location={jobsData?.location}
                workLevel={jobsData?.workLevel}
                majorSkills={jobsData?.majorSkills}
                ctcRange={jobsData?.salaryRange}
                employmentType={jobsData?.employmentType}
                view_referral_list={"View Referral List"}
                route_link={`/skill-links/${jobsData._id}`}
                navigationLink={`/refer-specific-job/${jobsData._id}`}
                onClickEdit={() => handleEditReferResource(jobsData)}
                editJobStatusApi={actions.editSkilLinksJobStatusAction}
                onClickJD={(e: any) =>
                  openDescURL(e, jobsData?.uploadJobDescription)
                }
                pageIn={"SkillLinks"}
              ></ReferResourceCard>
            ))
          ) : (
            <p className="no-data">{no_data_found}</p>
          )
        ) : employeeInfo.length > 0 ? (
          employeeInfo?.map((jobsData: any) => (
            <ReferResourceCard
              key={jobsData._id}
              id={jobsData._id}
              jobStatus={jobsData?.jobStatus}
              jobId={jobsData.jobId}
              jobDataId={jobsData._id}
              jobTitle={jobsData.jobTitle}
              buttonTitle={jobsData.jobStatus === 0 ? "Closed" : "Open"}
              modeOfJob={jobsData.modeOfWork}
              jobType={jobsData.jobCategory}
              postedOn={jobsData.timeSpan}
              ctcRange={jobsData?.salaryRange}
              jobDescription={`${(jobsData?.description).slice(0, 200)}....`}
              uploadDescription={jobsData?.uploadJobDescription}
              location={jobsData?.location}
              workLevel={jobsData?.workLevel}
              majorSkills={jobsData?.majorSkills}
              navigationLink={`/refer-specific-job/${jobsData._id}`}
              onClickJD={(e: any) =>
                openDescURL(e, jobsData?.uploadJobDescription)
              }
              employmentType={jobsData?.employmentType}
              pageIn={"SkillLinks"}
            ></ReferResourceCard>
          ))
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}

        {info.length > 0 || employeeInfo.length > 0 ? (
          <div className="show-entries-wrapper my-3">
            <div className="ml-3">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />
            </div>
            <TablePagination
              totalPages={totalPages}
              currentPage={page}
              onClick={handlePageChange}
            />
          </div>
        ) : (
          <></>
        )}

        <ModalComponents
          show={showEditReferResource}
          onHide={hideEditReferResource}
          title="Edit Job"
          modalBody={
            <EditPostAJob
              apiCallCompleted={apiCallCompleted}
              jobsData={jobs}
              hideEditReferResource={hideEditReferResource}
            />
          }
          className="modal-lg"
        />
      </MainCard>
    </div>
  );
};

export default ReferResource;
